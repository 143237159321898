import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import "../../styles/profil.css";
import "../../styles/all.css";
import "../../styles/modifyProduct.css";
import ModifyProduct from "./modifyProduct";
import useDocumentTitle from "../../services/documentTitle";

const BackofficeModify = () => {
  useDocumentTitle("Modification Produit - backoffice");

  useEffect(() => {
    const getOneProduct = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/stuff/${paramsId.id}`)
        .then((res) => {
          setItem(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(true);
        });
    };
    getOneProduct();
  }, []);

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});

  const paramsId = useParams();

  return (
    <div className="bgcolorffffff">
      <div className="vh-10"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="">
          <h1 className="text-center">Page backoffice Produit</h1>
        </div>
      </div>
      {loading === true ? (
        <div>
          {!item ? (
            <div
              style={{ height: "90vh" }}
              className="d-flex justify-content-center align-items-center"
            >
              <h1 className="text-center">Ce produit n'existe plus 😕</h1>
            </div>
          ) : (
            <ModifyProduct item={item} />
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center vh-25 align-items-center">
          <ThreeDots color="#00BFFF" height={80} width={80} />
        </div>
      )}
    </div>
  );
};

export default BackofficeModify;
