import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import "../../styles/all.css";
import "../../styles/profil.css";
import { ThreeDots } from "react-loader-spinner";
import MyOrderProduct from "./myOrderProduct";
import { formatCurrency } from "../../services/formatCurrency";
import Pagination from "../../components/pagination";
import useDocumentTitle from "../../services/documentTitle";

const MyOrder = () => {
  useDocumentTitle("Commande");

  const myRef = useRef();

  const [order, setOrder] = useState([]);
  const [product, setProduct] = useState([]);
  const [itemLoading, setItemLoading] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage, setProductPerPage] = useState(5);

  const indexOfLastProduct = currentPage * productPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productPerPage;

  const currentProduct = order.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    const getProducts = async () => {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/stuff/`);
      setProduct(data);
      setItemLoading(true);
    };
    getProducts();
    async function getAllOrder() {
      await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/order/getorder`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setOrderLoading(true);
          setOrder(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    getAllOrder();
  }, []);

  const navigateToOrder = useNavigate();

  const goToOrderTrack = (itemId) => {
    navigateToOrder(`/progress-tracker/${itemId}`);
  };

  const goToRefund = (itemId) => {
    navigateToOrder(`/refund/${itemId}`);
  };

  return (
    <div className="p-xl-5 py-5 container" ref={myRef}>
      <div>
        <div className="d-flex gap-2 align-items-center"></div>
        {currentProduct.length > 0 ? (
          <div className="p-xl-5">
            <div>
              <h3>Mes commandes</h3>
              {orderLoading ? (
                <div className="p-xl-4">
                  {currentProduct
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((item, index) => {
                      return (
                        <Container key={index} className="orderContainerProfil">
                          <Row className="orderDetails font-size-1 justify-content-between">
                            <Col md="auto">
                              <div>
                                <span className="fontRedHatDisplay color9e9e9e">
                                  COMMANDE EFFECTUÉE LE
                                </span>
                              </div>
                              <div>
                                <span>
                                  {new Date(
                                    item.createdAt
                                  ).toLocaleDateString()}
                                </span>
                              </div>
                            </Col>
                            <Col md="auto">
                              <div>
                                <span className="fontRedHatDisplay color9e9e9e">
                                  TOTAL
                                </span>
                              </div>
                              <div>
                                <span>{formatCurrency(item.amount_total)}</span>
                              </div>
                            </Col>
                            <Col md="auto">
                              <div>
                                <span className="fontRedHatDisplay color9e9e9e">
                                  LIVRAISON À
                                </span>
                              </div>
                              <div>
                                <span>{item.addressShipping.city}</span>
                              </div>
                              <div>
                                <span>{item.addressShipping.line1}</span>
                              </div>
                              <div>
                                <span>{item.addressShipping.line2}</span>
                              </div>
                              <div>
                                <span>{item.addressShipping.postal_code}</span>
                              </div>
                            </Col>
                            <Col md="auto">
                              <div>
                                <span className="fontRedHatDisplay color9e9e9e">
                                  N° DE COMMANDE
                                </span>
                              </div>
                              <div>
                                <span>{item.paymentIntentId}</span>
                              </div>
                            </Col>
                          </Row>
                          {itemLoading ? (
                            <Col>
                              {item.metadata.map((cart) => {
                                return (
                                  <MyOrderProduct
                                    key={cart.id}
                                    {...cart}
                                    product={product}
                                  />
                                );
                              })}
                              <Col className="d-flex flex-column justify-content-center align-items-center gap-2 p-md-5 p-2">
                                {!item.return ? (
                                  <Row>
                                    <Button
                                      onClick={() => goToRefund(item._id)}
                                    >
                                      Retourner ou remplacer des articles
                                    </Button>
                                  </Row>
                                ) : (
                                  <Row>
                                    <Alert variant="secondary">
                                      <span className="font-size-1 text-center">
                                        Cette commande vous sera remboursée au
                                        moment où nous la recevrons.
                                      </span>
                                    </Alert>
                                  </Row>
                                )}
                                <Row>
                                  <Button
                                    onClick={() => goToOrderTrack(item._id)}
                                  >
                                    Suivre votre colis
                                  </Button>
                                </Row>
                              </Col>
                            </Col>
                          ) : (
                            <div className="d-flex justify-content-center vh-25 align-items-center">
                              <ThreeDots
                                color="#00BFFF"
                                height={80}
                                width={80}
                              />
                            </div>
                          )}
                        </Container>
                      );
                    })}
                </div>
              ) : (
                <div className="d-flex justify-content-center vh-25 align-items-center">
                  <ThreeDots color="#00BFFF" height={80} width={80} />
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center paginationDiv">
              <Pagination
                productPerPage={productPerPage}
                totalProducts={order.length}
                paginate={paginate}
                currentPage={currentPage}
                myRef={myRef}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="vh-30 d-flex justify-content-center align-items-center">
              <div className="color000000">
                <h1>Aucune commandes ne peut être affiché</h1>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyOrder;
