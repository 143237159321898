import React, { useContext, useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { AuthContext } from "../services/authProvider.jsx";
import "../styles/all.css";
import "../styles/contact.css";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const MailFormFc = ({ page }) => {
  const ctx = useContext(AuthContext);

  const token = localStorage.getItem("jwt");

  const [msg, setMsg] = useState();

  const message = useRef();

  function handleSubmitCtx(e) {
    e.preventDefault();
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/auth/ctx/email`,
      data: { message: message.current.value },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        setMsg("Une erreur s'est produite, veuillez réessayer.");
        console.log(error);
      });
  }

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  };

  const MailSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "trop petit!")
      .max(50, "trop long!")
      .required("Ce champ est obligatoire"),
    lastName: Yup.string()
      .min(2, "trop petit!")
      .max(50, "trop long!")
      .required("Ce champ est obligatoire"),
    email: Yup.string()
      .email("email invalide")
      .required("Ce champ est obligatoire"),
    message: Yup.string().required("Ce champ est obligatoire"),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/auth/client/email`,
      data: values,
    })
      .then(() => {
        setSubmitting(false);
        resetForm();
        window.location.reload();
      })
      .catch((error) => {
        setSubmitting(false);
        resetForm();
        setMsg("Une erreur s'est produite, veuillez réessayer.");
        console.log(error);
      });
  };

  return (
    <>
      {ctx ? (
        <Form onSubmit={handleSubmitCtx}>
          <Form.Group
            className="mb-3 p-2"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label
              style={{ color: page === "dark" ? "white" : "black" }}
              className="fontLato"
            >
              Message
            </Form.Label>
            <Form.Control
              style={{
                backgroundColor: page === "dark" ? "white" : "#eeeeee",
              }}
              ref={message}
              as="textarea"
              rows={5}
              placeholder="Votre message"
              required
            />
          </Form.Group>
          <Button className="contact-button" type="submit">
            Envoyer
          </Button>
          <span className="text-danger">{msg}</span>
        </Form>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={MailSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group className="my-3">
                    <Form.Label
                      style={{ color: page === "dark" ? "white" : "black" }}
                      className=" fontLato"
                    >
                      Prénom
                    </Form.Label>
                    <Form.Control
                      style={{
                        backgroundColor: page === "dark" ? "white" : "#eeeeee",
                      }}
                      required
                      name="firstName"
                      type="text"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Entrez votre Prénom."
                      isValid={touched.firstName && !errors.firstName}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="my-3">
                    <Form.Label
                      style={{ color: page === "dark" ? "white" : "black" }}
                      className=" fontLato"
                    >
                      Nom
                    </Form.Label>
                    <Form.Control
                      style={{
                        backgroundColor: page === "dark" ? "white" : "#eeeeee",
                      }}
                      required
                      name="lastName"
                      type="text"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Entrez votre Nom."
                      isValid={touched.lastName && !errors.lastName}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="my-3" controlId="formBasicEmail">
                <Form.Label
                  style={{ color: page === "dark" ? "white" : "black" }}
                  className=" fontLato"
                >
                  Adresse e-mail
                </Form.Label>
                <Form.Control
                  style={{
                    backgroundColor: page === "dark" ? "white" : "#eeeeee",
                  }}
                  required
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Entrez votre adresse e-mail."
                  isValid={touched.email && !errors.email}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3 p-2"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label
                  style={{ color: page === "dark" ? "white" : "black" }}
                  className=" fontLato"
                >
                  Message
                </Form.Label>
                <Form.Control
                  style={{
                    backgroundColor: page === "dark" ? "white" : "#eeeeee",
                  }}
                  value={values.message}
                  name="message"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as="textarea"
                  rows={5}
                  placeholder="Votre message"
                  isValid={touched.message && !errors.message}
                  isInvalid={!!errors.message}
                  required
                />
              </Form.Group>
              <Button className="contact-button" type="submit">
                Envoyer
              </Button>
              <span className="text-danger">{msg}</span>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default MailFormFc;
