import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/all.css";
import "../../styles/product.css";

const ModalImage = ({ onHide, show, imgSrc }) => {
  const [MousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  const [mouseMove, setMouseMove] = useState(false);

  function zoomImage(event) {
    const elem = event.target;
    const { top, left } = elem.getBoundingClientRect();

    const x = event.pageX - left - window.pageXOffset;
    const y = event.pageY - top - window.pageYOffset;

    setMousePosition({
      x: x,
      y: y,
    });
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modalMainBody"
      centered
    >
      <div>
        <Modal.Header
          closeButton
          className="modalHeader"
          style={{ backgroundColor: "#5287c0", border: "none" }}
        ></Modal.Header>
        <div style={{ width: "100%", height: "100%" }}>
          <div
            onMouseMove={(e) => zoomImage(e)}
            onMouseEnter={() => setMouseMove(true)}
            onMouseLeave={() => setMouseMove(false)}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: "5",
              backgroundColor: "transparent",
            }}
          ></div>
          <img
            src={imgSrc}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              transform: mouseMove ? `scale(1.6)` : "scale(1)",
              transformOrigin: mouseMove
                ? `${MousePosition.x}px ${MousePosition.y}px`
                : "center",
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalImage;
