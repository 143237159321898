import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "../../styles/all.css";
import "../../styles/profil.css";
import { ThreeDots } from "react-loader-spinner";
import InfoUpdate from "./infoUpdate";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import useDocumentTitle from "../../services/documentTitle";

const passwordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .min(8, "Mot de passe doit être plus grand que 8 caractères")
    .max(50, "Mot de passe doit être plus petit que 50 caractères")
    .required("Ce champ est obligatoire"),
  newPassword: yup
    .string()
    .min(8, "Mot de passe doit être plus grand que 8 caractères")
    .max(50, "Mot de passe doit être plus petit que 50 caractères")
    .required("Ce champ est obligatoire"),
  newConfirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Le mot de passe doit être identifique!")
    .required("Ce champ est obligatoire"),
});

const MyInfo = () => {
  useDocumentTitle("Info");

  const token = localStorage.getItem("jwt");

  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const [msg, setMsg] = useState("");
  const [className, setClassName] = useState("");

  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const [passwordType3, setPasswordType3] = useState("password");

  useEffect(() => {
    const getInfo = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/auth/user/info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(true);
        });
    };
    getInfo();
  }, []);

  const postPassword = (values, { setSubmitting, resetForm }) => {
    axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/auth/update/password`,
      data: values,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setSubmitting(false);
        resetForm();
        setMsg(response.data.message);
        setClassName("success");
      })
      .catch((error) => {
        setSubmitting(false);
        resetForm();
        console.error(error);
        if (error.response.status === 401) {
          setMsg(error.response.data.message);
          setClassName("danger");
        }
      });
  };

  return (
    <div className="p-xl-5 py-5 container">
      <div>
        <div className="d-flex gap-2 align-items-center">
          <i className="fa-solid fa-user font-size-1_3"></i>
          <h3>Mes informations</h3>
        </div>
        <div className="p-xl-5">
          <div>
            <div className="py-3">
              <span className="font-size-1_4  color9e9e9e fontRedHatDisplay">
                Mon compte
              </span>
            </div>
            <div className="p-xl-4">
              <hr />
              {loading === true ? (
                <InfoUpdate userInfo={userInfo} />
              ) : (
                <div className="d-flex justify-content-center vh-25 align-items-center">
                  <ThreeDots color="#00BFFF" height={80} width={80} />
                </div>
              )}
              <div className="p-xl-5  py-5">
                <span className="font-size-1_4 fontRedHatDisplay">
                  Changer mon mot de passe
                </span>
              </div>
              <hr />
              <Formik
                validationSchema={passwordSchema}
                onSubmit={postPassword}
                initialValues={{
                  oldPassword: "",
                  newPassword: "",
                  newConfirmPassword: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Container>
                      <Row>
                        <Col sm>
                          <span className="fw-bolder">Ancien mot de passe</span>
                        </Col>
                        <Col sm>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                placeholder="Ancien mot de passe"
                                type={passwordType1}
                                name="oldPassword"
                                value={values.oldPassword}
                                onChange={handleChange}
                                isValid={
                                  touched.oldPassword && !errors.oldPassword
                                }
                                isInvalid={!!errors.oldPassword}
                                onBlur={handleBlur}
                                required
                              />
                              <InputGroup.Text
                                onClick={() =>
                                  passwordType1 === "password"
                                    ? setPasswordType1("text")
                                    : setPasswordType1("password")
                                }
                                style={{ cursor: "pointer" }}
                                id="oldPassword"
                              >
                                {passwordType1 === "password" ? (
                                  <i className="fa-regular fa-eye-slash"></i>
                                ) : (
                                  <i className="fa-regular fa-eye"></i>
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component="span"
                              name="oldPassword"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                    <hr />
                    <Container>
                      <Row>
                        <Col sm>
                          <span className="fw-bolder">
                            Nouveau mot de passe
                          </span>
                        </Col>
                        <Col sm>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                placeholder="Nouveau mot de passe"
                                type={passwordType2}
                                name="newPassword"
                                value={values.newPassword}
                                onChange={handleChange}
                                isValid={
                                  touched.newPassword && !errors.newPassword
                                }
                                isInvalid={!!errors.newPassword}
                                onBlur={handleBlur}
                                required
                              />
                              <InputGroup.Text
                                onClick={() =>
                                  passwordType2 === "password"
                                    ? setPasswordType2("text")
                                    : setPasswordType2("password")
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {passwordType2 === "password" ? (
                                  <i className="fa-regular fa-eye-slash"></i>
                                ) : (
                                  <i className="fa-regular fa-eye"></i>
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component="span"
                              name="newPassword"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                    <hr />
                    <Container>
                      <Row>
                        <Col sm>
                          <span className="fw-bolder">
                            Confirmer nouveau mot de passe
                          </span>
                        </Col>
                        <Col sm>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                placeholder="Confirmer nouveau mot de passe"
                                type={passwordType3}
                                name="newConfirmPassword"
                                value={values.newConfirmPassword}
                                onChange={handleChange}
                                isValid={
                                  touched.newConfirmPassword &&
                                  !errors.newConfirmPassword
                                }
                                isInvalid={!!errors.newConfirmPassword}
                                onBlur={handleBlur}
                                required
                              />
                              <InputGroup.Text
                                onClick={() =>
                                  passwordType3 === "password"
                                    ? setPasswordType3("text")
                                    : setPasswordType3("password")
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {passwordType3 === "password" ? (
                                  <i className="fa-regular fa-eye-slash"></i>
                                ) : (
                                  <i className="fa-regular fa-eye"></i>
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component="span"
                              name="newConfirmPassword"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>

                    <hr />
                    <Row>
                      <Alert variant={className}>{msg}</Alert>
                    </Row>
                    <Row>
                      <div>
                        <Link to="/reset">
                          <span>mot de passe oublié ?</span>
                        </Link>
                      </div>
                    </Row>

                    <div className="d-flex justify-content-center">
                      <Button variant="primary" type="submit">
                        Envoyer
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyInfo;
