import React, { useRef, useState } from "react";
import "../styles/login.css";
import "../styles/all.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import useDocumentTitle from "../services/documentTitle";

const ResetMdp = () => {
  useDocumentTitle("Mot de passe oublié");

  const email = useRef();
  const [msg, setMsg] = useState({ type: "", message: "" });

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/resetPwd`, {
        email: email.current.value,
      })
      .then((response) => {
        setMsg({ type: "success", message: response.data.message });
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setMsg({ type: "error", message: error.response.data.message });
      });
  };
  return (
    <div className="bgcolorffffff p-5 vh-100">
      <h1 className="text-center p-5 m-5">Mot de passe oublié</h1>

      <Container className="reset-password-container">
        <div className="reset-password-form-wrapper">
          <div className="">
            <h5 className="">Réinitialiser mon mot de passe</h5>
            <p>
              Saisissez l'adresse e-mail liée à votre compte, et nous vous
              enverrons un lien de réinitialisation.
            </p>
          </div>
          <Form className="" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Adresse e-mail</Form.Label>
              <Form.Control
                ref={email}
                required
                name="email"
                type="email"
                placeholder="Entrez votre adresse e-mail."
              />
            </Form.Group>
            <div className="d-flex justify-content-center m-5">
              <Button
                className="w-20 borderRadius_15px"
                variant="primary"
                type="submit"
              >
                Validation
              </Button>
            </div>
            <Alert
              variant={
                msg.type === "success"
                  ? "success"
                  : msg.type === "error"
                  ? "danger"
                  : null
              }
              className="text-center"
            >
              <span>{msg.message}</span>
            </Alert>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default ResetMdp;
