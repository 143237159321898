export function truncateString(string) {
  const maxLength = 70;
  if (string.length >= maxLength) {
    let text = string.substr(0, maxLength) + "...";
    return text;
  }

  return string;
}

export function truncateName(string) {
  const maxLength = 20;
  if (string.length >= maxLength) {
    let text = string.substr(0, maxLength) + "...";
    return text;
  }

  return string;
}

export function deliveryTrad(string) {
  if (string === "pending") {
    return "En préparation";
  }
  if (string === "send") {
    return "Commande envoyée";
  }
  if (string === "delivered") {
    return "Commande livrée";
  }
}

export function getAnniversary(date) {
  var today = new Date();
  var birthDate = new Date(date);
  var year = today.getFullYear() - birthDate.getFullYear();
  var month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    year--;
    var month = 12 + month;
  }
  var age = `${year} Ans, ${month} Mois`;

  return age;
}
