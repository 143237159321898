import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Stack from "react-bootstrap/Stack";
import { useShoppingCart } from "../../services/shoppingCartContext.jsx";
import { CartItem } from "./cartItem";
import "../../styles/all.css";
import "../../styles/productCart.css";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { OrderButton } from "./orderCart";
import { formatNumber } from "../../services/formatCurrency";

export function ShoppingCart({ isOpen }) {
  const { closeCart, cartItems } = useShoppingCart();

  const [loading, setLoading] = useState(false);
  const [storeItems, setStoreItems] = useState();

  useEffect(() => {
    const getProducts = async () => {
      axios.get(`${process.env.REACT_APP_API_URL}/stuff/`).then((response) => {
        setStoreItems(response.data);
        setLoading(true);
      });
    };
    getProducts();
  }, []);

  return (
    <Offcanvas show={isOpen} onHide={closeCart}>
      <Offcanvas.Header closeButton style={{ marginTop: "10px" }}>
        <Offcanvas.Title>
          <i
            style={{ color: "black", marginRight: "10px" }}
            className="fa-solid fa-cart-shopping"
          ></i>
          Votre Panier
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ display: "flex", flexDirection: "column" }}>
        {loading ? (
          <Stack gap={3}>
            {cartItems.map((item) => (
              <CartItem key={item.id} {...item} storeItems={storeItems} />
            ))}
            <div className="ms-auto fw-bold fs-5">
              Total:{" "}
              {cartItems
                .reduce((total, cartItem) => {
                  const item = storeItems.find((i) => i._id === cartItem.id);

                  const price = formatNumber(item.price);

                  const totalPrice = total + (price || 0) * cartItem.quantity;
                  return totalPrice;
                }, 0)
                .toFixed(2)}{" "}
              €
            </div>
          </Stack>
        ) : (
          <div className="d-flex justify-content-center vh-25 align-items-center">
            <ThreeDots color="#00BFFF" height={80} width={80} />
          </div>
        )}
        <OrderButton cartItems={cartItems} />
      </Offcanvas.Body>
    </Offcanvas>
  );
}
