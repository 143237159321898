import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AuthContext } from "../../services/authProvider.jsx";
import "../../styles/all.css";
import "../../styles/product.css";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { formatCurrency } from "../../services/formatCurrency";
import ModalImage from "../boutique/modalImage";
import { truncateName, getAnniversary } from "../../services/formatString";
import useDocumentTitle from "../../services/documentTitle";

const Dog = () => {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});

  const paramsId = useParams();

  const [error, setError] = useState("");

  useEffect(() => {
    const getOneDog = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/dog/${paramsId.id}`)
        .then((response) => {
          setItem(response.data);
          setLoading(true);
        })
        .catch(() => {
          setError("Une erreur c'est produite, le chien n'existe pas !");
        });
    };
    getOneDog();
  }, [paramsId]);

  return (
    <div
      style={{ backgroundColor: "#ffffff", paddingTop: "7vh" }}
      className="p-t-5 mainBody"
    >
      {loading === true ? (
        <div>
          {item ? (
            <CurrentDog item={item} />
          ) : (
            <div
              style={{ height: "90vh" }}
              className="d-flex justify-content-center align-items-center"
            >
              <h1 className="text-center">Ce chien n'existe plus 😕</h1>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center vh-25 align-items-center">
          {!error ? (
            <ThreeDots color="#00BFFF" height={80} width={80} />
          ) : (
            <div
              style={{ height: "90vh" }}
              className="d-flex justify-content-center align-items-center"
            >
              <h1 className="text-center">{error}</h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dog;

export function CurrentDog({ item }) {
  const ctx = useContext(AuthContext);

  const [mainImage, setMainImage] = useState();
  const handleImages = (src) => {
    setMainImage(src);
  };

  const [modalShow, setModalShow] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  useDocumentTitle(`${item.name} - Chiens`);

  return (
    <div>
      <ModalImage
        show={modalShow}
        imgSrc={imgSrc}
        onHide={() => setModalShow(false)}
      />
      <div
        style={{
          width: "100%",
          height: "20vh",
          backgroundColor: "#007ddd",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
        }}
      >
        <div className="fontRedHatDisplay colorffffff">
          <h1 className="fs-1">Détails du chien</h1>
        </div>
        <div className="detailsProdLink">
          <Link
            style={{ textDecoration: "none" }}
            className="fontLato colorffffff font-size-1"
            to="/"
          >
            Accueil
          </Link>
          <span className="fontLato colorffffff font-size-1">{">"}</span>
          <Link
            style={{ textDecoration: "none" }}
            className="fontLato colorffffff font-size-1"
            to="/dogs"
          >
            Chiens
          </Link>
          <span className="fontLato colorffffff font-size-1">{">"}</span>
          <span className="fontLato colorffffff font-size-1">
            {truncateName(item.name)}
          </span>
        </div>
      </div>
      <section className="sectionMainProduct">
        <div className="d-flex flex-column flex-xl-row">
          <div className="bodyImageProduct">
            <div className="column-picture-div">
              {item.images.map((image, index) => {
                return (
                  <div key={index}>
                    <img
                      src={image}
                      alt=""
                      onClick={(e) =>
                        handleImages(e.target.getAttribute("src"))
                      }
                      className="product-img"
                    />
                  </div>
                );
              })}
            </div>

            <div className="main-picture-div">
              {!mainImage ? (
                <img
                  src={item.images[0]}
                  alt=""
                  className="product-img"
                  onClick={(e) => {
                    setModalShow(true);
                    setImgSrc(e.target.src);
                  }}
                />
              ) : (
                <img
                  src={mainImage}
                  alt=""
                  className="product-img"
                  onClick={(e) => {
                    setModalShow(true);
                    setImgSrc(e.target.src);
                  }}
                />
              )}
            </div>
          </div>

          <div
            style={{ backgroundColor: "white", gap: "2rem" }}
            className="bodyTextProduct"
          >
            <div className="d-flex justify-content-center">
              <span className="color000000 font-size-1_6 fontMontserrat">
                {item.name}
              </span>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div>
                <span>Genre :</span>
                <span> {item.gender}</span>
              </div>
              <div>
                <span>Age :</span>
                <span> {getAnniversary(item.anniversary)}</span>
              </div>
              <div>
                <span>Nés le :</span>
                <span> {new Date(item.anniversary).toLocaleDateString()}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <p className="color1F8ADD font-size-1_7 fontMontserrat">
                {formatCurrency(item.price)}
              </p>
            </div>

            <div className="d-flex justify-content-center">
              <p className="color000000 font-size-1_2 product-description">
                {item.description}
              </p>
            </div>
            <div className="d-flex gap-2">
              <div>
                <Button variant="primary">Adopter</Button>
              </div>
              {ctx && (
                <div className="mb-3">
                  <Link to={`/backoffice/modifyDog/${item._id}`}>
                    <Button className="btn btn-danger">Modifier</Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="p-md-5 p-1 ">
        <div className="m-5">
          <hr />
        </div>
        <div className="pb-5">
          <div className="d-flex header-details">
            <div className="m-2">
              <i className="fa-solid fa-circle-exclamation colorffffff font-size-1_6"></i>
            </div>
            <div className="text-details">
              <p className="colorffffff font-size-1_6">
                Informations sur ce chien{" "}
              </p>
            </div>
          </div>

          <div
            style={{ backgroundColor: "", color: "white" }}
            className="dog-details-grid "
          >
            <div className="">
              <div>
                <span className="fontMontserrat font-size-1">Race :</span>
              </div>
              <div>
                <span className="font-size-0_9 fontLato">{item.race}</span>
              </div>
            </div>
            <div className="">
              <div>
                <span className="fontMontserrat font-size-1">Sexe :</span>
              </div>
              <div>
                <span className="font-size-0_9 fontLato">{item.gender}</span>
              </div>
            </div>
            <div className="">
              <div>
                <span className="fontMontserrat font-size-1">Prix :</span>
              </div>
              <div>
                <span className="font-size-0_9 fontLato">
                  {formatCurrency(item.price)}
                </span>
              </div>
            </div>
            <div className="">
              <div>
                <span className="fontMontserrat font-size-1">Age :</span>
              </div>
              <div>
                <span className="font-size-0_9 fontLato">
                  {getAnniversary(item.anniversary)}
                </span>
              </div>
            </div>
            <div className="">
              <div>
                <span className="fontMontserrat font-size-1">Naissance :</span>
              </div>
              <div>
                <span className="font-size-0_9 fontLato">
                  {new Date(item.anniversary).toLocaleDateString()}
                </span>
              </div>
            </div>
            <div className="">
              <div>
                <span className="fontMontserrat font-size-1">
                  N°d’identification :
                </span>
              </div>
              <div>
                <span className="font-size-0_9 fontLato">
                  {item.identificationId}
                </span>
              </div>
            </div>
            {item.techDescription.map((tech, index) => {
              return (
                <div className="" key={index}>
                  <div>
                    <span className="fontMontserrat font-size-1">
                      {tech.titre} :
                    </span>
                  </div>
                  <div>
                    <span className="font-size-0_9 fontLato">
                      {" "}
                      {tech.valeur}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
