import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../styles/all.css";
import "../../styles/profil.css";
import { ThreeDots } from "react-loader-spinner";
import MyOrderProduct from "./refundDashboardProduct";
import { formatCurrency } from "../../services/formatCurrency";
import { deliveryTrad } from "../../services/formatString";
import Pagination from "../pagination";

const RefundDashboard = ({ json, searchInput, orderCheckbox, checked }) => {
  const myRef = useRef();

  const [order, setOrder] = useState([]);
  const [product, setProduct] = useState([]);
  const [itemLoading, setItemLoading] = useState(false);

  const searchFunction = () => {
    if (searchInput !== "") {
      return json.filter((item) => {
        return Object.values(item.orderObj)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
    } else {
      return json;
    }
  };

  const checkboxFunction = (result1) => {
    if (orderCheckbox.length === 0) {
      return result1;
    }
    if (orderCheckbox.length > 0) {
      return result1.filter((x) => orderCheckbox.includes(x.refund.status));
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage] = useState(5);

  // Get current Todo
  const indexOfLastProduct = currentPage * productPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productPerPage;

  const currentProduct = order.slice(indexOfFirstProduct, indexOfLastProduct);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const getProducts = async () => {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/stuff/`);
      setProduct(data);
      setItemLoading(true);
    };
    getProducts();
  }, []);

  useEffect(() => {
    const result1 = searchFunction();
    const result2 = checkboxFunction(result1);

    setOrder(result2);
  }, [searchInput, checked]);

  return (
    <div className="d-flex flex-column align-items-center" ref={myRef}>
      <div className="font-size-1_2 py-3">
        <span>Total de commandes :</span>
        <span> {json.length}</span>
      </div>
      {currentProduct.length > 0 ? (
        <div className="p-xl-4">
          {currentProduct
            .sort(
              (a, b) =>
                new Date(b.refund.createdAt) - new Date(a.refund.createdAt)
            )
            .map((item, index) => {
              return (
                <Container fluid="md" key={index} className="orderContainer">
                  <div className="text-center pb-3">
                    <h5>Information client</h5>
                  </div>
                  <Row style={{ padding: "0.5rem" }} className="font-size-1">
                    <Col>
                      <span className="color737373 fontRedHatDisplay">
                        Nom :
                      </span>
                      <span> {item.orderObj.name}</span>
                    </Col>
                    <Col>
                      <span className="color737373 fontRedHatDisplay">
                        Email :
                      </span>
                      <span> {item.orderObj.email}</span>
                    </Col>
                    <Col>
                      <span className="color737373 fontRedHatDisplay">
                        Téléphone :
                      </span>
                      <span>{item.orderObj.phone}</span>
                    </Col>
                  </Row>

                  <Row style={{ padding: "0.5rem" }} className="font-size-1">
                    <div style={{ padding: "0.2rem" }}>
                      <span className="color737373 fontRedHatDisplay">
                        UserId :
                      </span>
                      <span> {item.orderObj.userId}</span>
                    </div>
                    <div style={{ padding: "0.2rem" }}>
                      <span className="color737373 fontRedHatDisplay">
                        customerId :
                      </span>
                      <span> {item.orderObj.customerId}</span>
                    </div>
                    <div style={{ padding: "0.2rem" }}>
                      <span className="color737373 fontRedHatDisplay">
                        paymentIntentId :
                      </span>
                      <span> {item.orderObj.paymentIntentId}</span>
                    </div>
                    <div className="text-center p-3">
                      <h5>Information remboursement</h5>
                    </div>
                  </Row>
                  <Row className="px-3">
                    <Col>
                      <span className="color737373 fontRedHatDisplay">
                        Retour de commande crée le :
                      </span>

                      <span className="">
                        {" "}
                        {new Date(item.refund.createdAt).toLocaleDateString()}
                      </span>
                    </Col>
                  </Row>

                  <Row className="p-3">
                    {" "}
                    <Col className="font-size-1 ">
                      <span className="color737373 fontRedHatDisplay">
                        Etat de la commande :
                      </span>
                      <span> {item.refund.status}</span>
                      <span> {deliveryTrad(item.refund.status)} </span>
                      {item.refund.status === "En attente" && (
                        <i
                          className="fa-solid fa-circle"
                          style={{ color: "red" }}
                        ></i>
                      )}

                      {item.refund.status === "Remboursées" && (
                        <i
                          className="fa-solid fa-circle"
                          style={{ color: "green" }}
                        ></i>
                      )}
                    </Col>
                    <Col className="d-flex flex-row-reverse">
                      {item.refund.status === "En attente" && (
                        <Button>Commande envoyée</Button>
                      )}
                      {item.refund.status === "Remboursées" && (
                        <Button disabled>Commande Remboursées</Button>
                      )}
                    </Col>
                  </Row>
                  <Row className="orderDetails font-size-1 justify-content-between">
                    <Col md="auto">
                      <div>
                        <span className="fontRedHatDisplay color9e9e9e">
                          COMMANDE EFFECTUÉE LE
                        </span>
                      </div>
                      <div>
                        <span>
                          {new Date(
                            item.orderObj.orderCreated
                          ).toLocaleDateString()}
                        </span>
                      </div>
                    </Col>
                    <Col md="auto">
                      <div>
                        <span className="fontRedHatDisplay color9e9e9e">
                          TOTAL
                        </span>
                      </div>
                      <div>
                        <span>
                          {formatCurrency(
                            item.orderObj.shipping_cost.amount_total
                          )}
                        </span>
                      </div>
                    </Col>
                    <Col md="auto">
                      <div>
                        <span className="fontRedHatDisplay color9e9e9e">
                          LIVRAISON À
                        </span>
                      </div>
                      <div>
                        <span>{item.orderObj.addressShipping.city}</span>
                      </div>
                      <div>
                        <span>{item.orderObj.addressShipping.line1}</span>
                      </div>
                      <div>
                        <span>{item.orderObj.addressShipping.line2}</span>
                      </div>
                      <div>
                        <span>{item.orderObj.addressShipping.postal_code}</span>
                      </div>
                    </Col>
                    <Col md="auto">
                      <div>
                        <span className="fontRedHatDisplay color9e9e9e">
                          N° DE COMMANDE
                        </span>
                      </div>
                      <div>
                        <span>{item.orderObj.paymentIntentId}</span>
                      </div>
                    </Col>
                  </Row>

                  {itemLoading ? (
                    <Col>
                      {item.refund.refundObj.map((cart) => {
                        return (
                          <MyOrderProduct
                            key={cart.id}
                            {...cart}
                            product={product}
                          />
                        );
                      })}
                    </Col>
                  ) : (
                    <div className="d-flex justify-content-center vh-25 align-items-center">
                      <ThreeDots color="#00BFFF" height={80} width={80} />
                    </div>
                  )}
                </Container>
              );
            })}
          <div className="d-flex justify-content-center paginationDiv">
            <Pagination
              productPerPage={productPerPage}
              totalProducts={order.length}
              paginate={paginate}
              currentPage={currentPage}
              myRef={myRef}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="vh-30 d-flex justify-content-center align-items-center">
            <div className="color000000">
              <h1>Aucun retours client ne peut être affiché</h1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RefundDashboard;
