import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { useShoppingCart } from "../../services/shoppingCartContext.jsx";
import { ThreeDots } from "react-loader-spinner";
import "../../styles/all.css";
import "../../styles/profil.css";
import CartInfoProduct from "./cartInfoProduct";
import { formatNumber } from "../../services/formatCurrency";
import useDocumentTitle from "../../services/documentTitle";

const CartInfo = () => {
  useDocumentTitle("Panier");

  const { cartItems } = useShoppingCart();

  const [loading, setLoading] = useState(false);
  const [storeItems, setStoreItems] = useState();

  useEffect(() => {
    const getProducts = async () => {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/stuff/`);
      setStoreItems(data);
      setLoading(true);
    };
    getProducts();
  }, []);

  const submitPayment = async () => {
    const token = localStorage.getItem("jwt");
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/stuff/stripe`,
      data: {
        cartItems: cartItems,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) return (window.location = res.data.url);
        return res.json().then((json) => Promise.reject(json));
      })
      .catch((e) => {
        console.error(e.error);
      });
  };

  return (
    <div className="p-xl-5 py-5 container">
      <div>
        <div className="d-flex gap-2 align-items-center"></div>
        <div className="p-xl-5">
          <div>
            <h3>Mon panier</h3>
            <div className="p-xl-4">
              {loading ? (
                <Container fluid="md">
                  {cartItems.map((item) => (
                    <CartInfoProduct
                      key={item.id}
                      {...item}
                      storeItems={storeItems}
                    />
                  ))}
                  <div className="ms-auto fw-bold fs-5">
                    Total:{" "}
                    {cartItems
                      .reduce((total, cartItem) => {
                        const item = storeItems.find(
                          (i) => i._id === cartItem.id
                        );

                        const price = formatNumber(item.price);

                        const totalPrice =
                          total + (price || 0) * cartItem.quantity;
                        return totalPrice;
                      }, 0)
                      .toFixed(2)}{" "}
                    €
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="btn-shopping-cart"
                      onClick={submitPayment}
                    >
                      Passer commande
                    </Button>
                  </div>
                </Container>
              ) : (
                <div className="d-flex justify-content-center vh-25 align-items-center">
                  <ThreeDots color="#00BFFF" height={80} width={80} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartInfo;
