import React, { useState, useEffect } from "react";
import "../styles/all.css";
import "../styles/chiens.css";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import AllDog from "../components/chiens/allDog";
import MultiRangeSlider from "../services/multiRangeSlider";
import useDocumentTitle from "../services/documentTitle";

const Chiens = () => {
  useDocumentTitle("Chiens");

  const [loading, setLoading] = useState(false);
  const [json, setJson] = useState();

  useEffect(() => {
    const getDogs = async () => {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/dog/`);

      setJson(data);
      setLoading(true);
    };
    getDogs();
  }, []);

  const [races, setRaces] = useState();
  const [loadingRaces, setLoadingRaces] = useState(false);

  useEffect(() => {
    const getRaces = async () => {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/dog/race`
      );
      setRaces(data);
      setLoadingRaces(true);
    };
    getRaces();
  }, []);

  const [sortRace, setSortRace] = useState("y");

  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAge] = useState();

  const [sortGender, setSortGender] = useState("0");

  const sortByGender = [
    { name: "Sexe du chien", value: "0" },
    { name: "Mâle", value: "1" },
    { name: "Femelle", value: "2" },
  ];

  const [sortType, setSortType] = useState("0");

  const sortByValue = [
    { name: "Trie par défault", value: "0" },
    { name: "Prix : par ordre croissant", value: "1" },
    { name: "Prix : par ordre décroissant", value: "2" },
    { name: "Dernières nouveautés", value: "3" },
  ];

  const [searchInput, setSearchInput] = useState("");

  return (
    <div className="chiens-main-div" style={{ paddingTop: "7vh" }}>
      <header className="header-Dog">
        <div className="backgroundHeaderDog">
          <div className="d-flex justify-content-center align-items-center h-100">
            <h1 className="colorffffff text-center fontMontserrat">
              Tous nos chiens
            </h1>
          </div>
        </div>
      </header>
      <section className="chiens-section-2"></section>
      <section className="section-header-chien p-3 p-xl-5">
        <div className="chiens-div-wrapper py-3 py-xl-5 colorffffff">
          <h2 className="fontOswald display-4">
            Achetez les animaux de compagnie parfaits
          </h2>
          <div className="elementor-divider-dogs "></div>
          <p className="fontRubik">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            eros elit, placerat quis mollis a, gravida a odio. Maecenas vitae
            semper eros. Vestibulum malesuada velit dui, eu accumsan nibh
            euismod in. Maecenas diam sapien, eleifend at gravida et, imperdiet
            quis orci. Nulla at convallis massa. Phasellus non elit neque. Sed
            congue aliquet pulvinar. Praesent et nibh quis dolor commodo
            iaculis. Donec mattis condimentum orci nec imperdiet.
          </p>
        </div>
      </section>
      <section className="chiens-section-2">
        <div className="chiens-section-2-div">
          <span id="chiens-span" className="fontRubik colorf0f0f0 font-size-1">
            NOS CHIENS
          </span>
          <h1 className="fontMerriweather colorf0f0f0"> Praesent et nibh</h1>
        </div>
      </section>
      <section className="chiens-section-3">
        <div className="section-chien-filtre">
          <div className="chien-filter-container">
            <div className="d-flex justify-content-center align-items-center">
              {loadingRaces ? (
                <Form.Select
                  className=""
                  onChange={(e) => setSortRace(e.target.value)}
                >
                  <option value="y">Race du chien</option>
                  {races.map((race, index) => (
                    <option key={index} value={race.name}>
                      {race.name}
                    </option>
                  ))}
                </Form.Select>
              ) : null}
            </div>
            <div className="">
              <Accordion className="AccordioWrapper">
                <Accordion.Item eventKey="0">
                  <Accordion.Header as="span">Age du chien</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <span>
                        Sélectionner l'âge du chien d'un mois à 20 ans{" "}
                      </span>
                    </div>
                    <MultiRangeSlider
                      min={0}
                      max={20}
                      onChange={({ min, max }) => {
                        setMinAge(min);
                        setMaxAge(max);
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Form.Select
                className=""
                aria-label="Default select example"
                onChange={(e) => setSortGender(e.target.value)}
              >
                {sortByGender.map((sortBy, idx) => (
                  <option key={idx} value={sortBy.value}>
                    {sortBy.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="search-filtre-wrapper-dogs">
              <div>
                <Form.Select
                  className=""
                  onChange={(e) => setSortType(e.target.value)}
                  aria-label="Default select example"
                >
                  {sortByValue.map((sortBy, idx) => (
                    <option key={idx} value={sortBy.value}>
                      {sortBy.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
          </div>
        </div>
        <div className="search-container-section">
          <div>
            <Form.Control
              style={{ backgroundColor: "white" }}
              onChange={(e) => setSearchInput(e.target.value)}
              className=""
              placeholder="Recherche..."
              type="text"
              id="inputFilter"
              aria-describedby="textFilter"
            />
          </div>
        </div>
        {loading === true ? (
          <div className="allDogs-container">
            <AllDog
              json={json}
              sortType={sortType}
              searchInput={searchInput}
              sortRace={sortRace}
              minAge={minAge}
              maxAge={maxAge}
              sortGender={sortGender}
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center vh-25 align-items-center">
            <ThreeDots color="#00BFFF" height={80} width={80} />
          </div>
        )}
      </section>
    </div>
  );
};

export default Chiens;
