import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import "../../styles/all.css";
import "../../styles/profil.css";
import { truncateString } from "../../services/formatString";
import { formatCurrency, formatNumber } from "../../services/formatCurrency";

const MyOrderProduct = ({ product, id, quantity }) => {
  const navigateToProduct = useNavigate();

  const goToProduct = (itemId) => {
    navigateToProduct(`/store/product/${itemId}`);
  };

  const item = product.find((i) => i._id === id);
  if (item == null) return null;

  const price = formatNumber(item.price);
  const itemTotalPrice = price * quantity;

  return (
    <Container>
      <Row>
        <Col
          sm={3}
          onClick={() => goToProduct(item._id)}
          className="imgContainer"
        >
          <img src={item.images[0]} alt="" className="imgProduct" />
        </Col>
        <Col sm className="d-flex flex-column gap-3 p-2">
          <div>
            <span className="fontRedHatDisplay font-size-1_2 color9e9e9e">
              {truncateString(item.name)}
            </span>
          </div>
          <div className="font-size-1">
            <span className="fontRedHatDisplay color737373">
              Prix a l'unité :
            </span>
            <span> {formatCurrency(item.price)}</span>
          </div>
          <div className="font-size-1">
            <span className="fontRedHatDisplay color737373">
              Quantité achetée :
            </span>
            <span> {quantity}</span>
          </div>
          <div className=" font-size-1 ">
            <span className="fontRedHatDisplay color737373">
              Total avec quentité :
            </span>{" "}
            <span>{formatCurrency(itemTotalPrice)}</span>
          </div>
          <div>
            <Button onClick={() => goToProduct(item._id)}>
              Afficher l'article
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MyOrderProduct;
