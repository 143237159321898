import React, { useContext } from "react";
import "../styles/accueil.css";
import "../styles/all.css";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthContext } from "../services/authProvider.jsx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo_apartment from "../images/accueil/apartment.png";
import Logo_phone from "../images/accueil/phone-call.png";
import Logo_pet1 from "../images/accueil/Group-1.png";
import Logo_pet3 from "../images/accueil/Group-3.png";
import Logo_pet4 from "../images/accueil/Group-4.png";
import Logo_pet8 from "../images/accueil/Group-8.png";
import dog1 from "../images/accueil/dog-1.png";
import useDocumentTitle from "../services/documentTitle";

const Home = () => {
  useDocumentTitle("Accueil");

  const ctx = useContext(AuthContext);
  return (
    <div>
      <header>
        <div className="home-text">
          <div>
            <h1 className="font-size-3 colorffffff text-center">
              Lorem ipsum dolor sit amet
            </h1>
            <p className="font-size-1_6 colorffffff text-center">
              Amet facilisis ullamcorper nec amet, cursus. Sodales ullamcorper
              nam amet, neque justo interdum facilisi ultricies. Rutrum
              pellentesque magna mi netus. A in aliquam, nisl ac nunc, velit
              ipsum convallis.
            </p>
          </div>
        </div>
        <Carousel fade>
          <Carousel.Item>
            <div id="img-1" className="test-img d-block "></div>
          </Carousel.Item>
          <Carousel.Item>
            <div id="img-2" className="test-img d-block "></div>
          </Carousel.Item>
          <Carousel.Item>
            <div id="img-3" className="test-img d-block "></div>
          </Carousel.Item>
        </Carousel>
      </header>
      <section className="section-1">
        <Container className="home-pages-link">
          <Row>
            <Col>
              <h5 className="fontMontserrat font-size-1_4">Pension</h5>
              <p className="p-2 font-size-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <Link className="p-2" to="/pension">
                <i className="fa-solid fa-circle-chevron-down font-size-1_8"></i>
              </Link>
            </Col>
            <Col>
              <h5 className="fontMontserrat font-size-1_4">
                Produit {"&"} Accessoire
              </h5>
              <p className="p-2 font-size-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <Link className="p-2" to="/store">
                <i className="fa-solid fa-circle-chevron-down font-size-1_8"></i>
              </Link>
            </Col>
            <Col>
              <h5 className="fontMontserrat font-size-1_4">Adopter un chien</h5>
              <p className="p-2 font-size-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <Link className="p-2" to="/dogs">
                <i className="fa-solid fa-circle-chevron-down font-size-1_8"></i>
              </Link>
            </Col>
            <Col>
              <h5 className="fontMontserrat font-size-1_4">Dressage</h5>
              <p className="p-2 font-size-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <Link className="p-2" to="/dressage">
                <i className="fa-solid fa-circle-chevron-down font-size-1_8"></i>
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="section-1-accueil-h1 ">
          <h1 className="color000000 text-center display-3 fontMontserrat">
            Lorem ipsum dolor sit amet
          </h1>
        </div>
        <div className="section-1-accueil-corgi py-3 py-lg-5">
          <div className="corgi-div-accueil">
            <img
              src={require("../images/accueil/home-corgi.png")}
              alt="corgi"
            />
          </div>
          <div className="accueil-div-office p-xl-5 p-3 ">
            <h3 className="color000000 display-5 p-2 fontComingSoon">
              Notre établissement
            </h3>
            <p className="color0000000 font-size-1_2 p-3 fontLato">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis
              risus nisi dui velit. Donec duis penatibus pulvinar porta
              pellentesque nulla. Velit id quis ultrices at quis. Amet facilisis
              ullamcorper nec amet, cursus. Sodales ullamcorper nam amet, neque
              justo interdum facilisi ultricies. Rutrum pellentesque magna mi
              netus. A in aliquam, nisl ac nunc, velit ipsum convallis. Odio
            </p>
            <div className="accueil-div-logo">
              <div className="d-flex flex-column align-items-center gap-2">
                <img src={Logo_apartment} alt="logo-apartment" />
                <h4 className="text-center fontComingSoon">
                  Notre emplacement
                </h4>
                <p className="text-center fontLato">
                  Dewi Sri St. 2891, <br /> Denpasar, Bali
                </p>
              </div>
              <div className="d-flex flex-column align-items-center gap-2">
                <img src={Logo_phone} alt="logo-phone" />
                <h4 className="text-center fontComingSoon">Notre contact</h4>
                <p className="text-center fontLato">
                  (+62) 8896-2220 <br /> petzy@domain.com
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="accueil-our-service">
          <div className="accueil-service-wrapper p-xl-5">
            <h2 className="colorffffff text-center display-6 p-3 fontComfortaa">
              Services que nous fournissons
            </h2>
            <p className="colorffffff text-center font-size-1_1 p-3 fontLato">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse vulputate iaculis erat, sit amet consectetur lacus
              finibus sed.
            </p>
            <div className="accueil-div-grid text-center">
              <div className="d-flex flex-column align-items-center ">
                <img src={Logo_pet1} alt="" />
                <span className="font-size-1_3 fontComingSoon py-2">
                  Service de pension pour chiens
                </span>
              </div>
              <div className="d-flex flex-column align-items-center ">
                <img src={Logo_pet4} alt="" />
                <span className="font-size-1_3 fontComingSoon py-2">
                  Service de dressage pour chiens
                </span>
              </div>
              <div className="d-flex flex-column align-items-center ">
                <img src={Logo_pet3} alt="" />
                <span className="font-size-1_3 fontComingSoon py-2">
                  Service de promenade pour chiens
                </span>
              </div>
              <div className="d-flex flex-column align-items-center ">
                <img src={Logo_pet8} alt="" />
                <span className="font-size-1_3 fontComingSoon py-2">
                  service de toilettages pour chiens
                </span>
              </div>
            </div>
          </div>
        </div>
        <section className="section-accueil-2">
          <div className="d-md-flex p-lg-5 px-2">
            <div className="accueil-dog-text-wrapper p-md-5 px-2">
              <h2 className="fontComfortaa display-4">Notre histoire</h2>
              <p className="fontLato font-size-1_1 ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                pretium dui ut dolor sodales placerat. Etiam viverra dolor
                ultrices nibh faucibus lacinia. Aenean pharetra dui nec sem
                pulvinar iaculis. Maecenas id arcu vel est imperdiet facilisis.
                Duis varius porta blandit. Donec feugiat nibh quis arcu
                convallis, nec pulvinar ex cursus. Ut non elit velit. Donec
                mauris justo, posuere a congue sit amet, dictum sit amet est.
                <br />
                <br />
                Integer justo neque, iaculis et odio vel, consequat congue
                velit. Nullam suscipit tortor vel ornare lacinia. Maecenas
                commodo mi dui, at lacinia nunc consectetur eu. Etiam tincidunt
                quis purus vel tincidunt. Vestibulum vehicula vehicula gravida.
                In ligula augue, porttitor vel iaculis eget, aliquet eu tellus.
                Vestibulum vehicula sem at dapibus bibendum. In convallis
                pellentesque ante.
              </p>
              <Link to="/contact">
                <button
                  style={{
                    borderRadius: "10px 0 10px 0",
                    backgroundColor: "#5287c0",
                    border: "none",
                    padding: "0.8rem",
                  }}
                  className="btn btn-primary fontLato"
                >
                  Contactez-nous
                </button>
              </Link>
            </div>
            <div className="accueil-dog-img-wrapper d-flex justify-content-center align-items-center ">
              <div className="accueil-dog-img-bg">
                <Col>
                  <div id="accueil-img-1" className="accueil-dog-image"></div>
                </Col>
                <Col className="d-flex flex-column gap-1">
                  <Row id="accueil-img-2" className="accueil-dog-image"></Row>
                  <Row id="accueil-img-3" className="accueil-dog-image"></Row>
                </Col>
              </div>
            </div>
          </div>
        </section>
        <div className="accueil-quotes">
          <div>
            <h3 className="fontComfortaa display-7">
              « Un chien est la seule chose sur terre qui vous aime plus qu'il
              ne s'aime lui-même. ”
            </h3>
            <span className="font-size-0_9 colorFADB83 fontRubik">
              Josh Billings
            </span>
          </div>
          <div className="d-flex flex-row-reverse">
            <img src={dog1} alt="" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
