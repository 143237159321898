import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import "../styles/all.css";
import "../styles/profil.css";

const Profil = () => {
  const page = useLocation();
  return (
    <div className="bgcolorffffff">
      <div className="vh-40 d-flex justify-content-center align-items-center">
        <div className="">
          <h1 className="text-center">Votre profil</h1>
        </div>
      </div>
      <div className="mainContent">
        <div>
          <Nav fill variant="pills" defaultActiveKey={page.pathname}>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/profil/info"
                className="nav-link-profil"
                eventKey="/profil/info"
              >
                Mes informations
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/profil/cartInfo"
                className="nav-link-profil"
                eventKey="/profil/cartInfo"
              >
                Mon panier
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/profil/myOrder"
                className="nav-link-profil"
                eventKey="/profil/myOrder"
              >
                Mes commandes
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default Profil;
