import React from "react";
import "../styles/all.css";
import "../styles/footer.css";
import websiteLogo from "../images/animal-cachorro-dog-svgrepo-com.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="p-xl-5 p-4">
      <Container>
        <Row className="footer-Row-Container">
          <Col>
            <div className="footer-div-img">
              <img src={websiteLogo} alt="logo" />
            </div>
            <p className="colorffffff font-size-0_9 p-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              pellentesque nec lacus sit amet finibus. Nulla non sollicitudin
              orci. Aliquam consequat metus in blandit faucibus.
            </p>
          </Col>
          <Col>
            <h4 className="colorffffff fontMontserrat">Navigation</h4>
            <ul className="colorffffff ">
              <li className="font-size-1">
                <div className="footer-nav-div">
                  <i className="fa-sharp fa-solid fa-chevron-right color1F8ADD" />
                  <Link to="/pension" className="colorffffff ">
                    Pension
                  </Link>
                </div>
              </li>
              <li className="font-size-1">
                <div className="footer-nav-div">
                  <i className="fa-sharp fa-solid fa-chevron-right color1F8ADD" />
                  <Link to="/dogs" className="colorffffff ">
                    Chiens
                  </Link>
                </div>
              </li>
              <li className="font-size-1">
                <div className="footer-nav-div">
                  <i className="fa-sharp fa-solid fa-chevron-right color1F8ADD" />
                  <Link to="/store" className="colorffffff ">
                    Boutique
                  </Link>
                </div>
              </li>
              <li className="font-size-1">
                <div className="footer-nav-div">
                  <i className="fa-sharp fa-solid fa-chevron-right color1F8ADD" />
                  <Link to="/dressage" className="colorffffff ">
                    Dressage
                  </Link>
                </div>
              </li>
            </ul>

            <Link to="contact" className="color1F8ADD">
              Nous contacter
            </Link>
          </Col>
          <Col>
            <h4 className="colorffffff fontMontserrat">Contact</h4>
            <ul>
              <li className="font-size-1">
                <i className="fa-solid fa-house color1F8ADD" />
                <span className="colorffffff">
                  720 Rue des ..... 42424 Paris
                </span>
              </li>
              <li className="font-size-1">
                <i className="fa-solid fa-phone-flip color1F8ADD" />
                <span className="colorffffff">06 00 00 00 00</span>
              </li>
              <li className="font-size-1">
                <i className="fa-solid fa-envelope color1F8ADD" />
                <span className="colorffffff">
                  Nous écrire ... bonjour@hotmail.com
                </span>
              </li>
            </ul>
            <span className="colorffffff font-size-1_2 fontMontserrat">
              nos réseaux
            </span>
            <Row className="color1F8ADD font-size-1_6 footer-div">
              <Col md={2}>
                <a href="#">
                  <i className="fa-brands fa-facebook"></i>
                </a>
              </Col>
              <Col md={2}>
                <a href="#">
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </Col>
              <Col md={2}>
                <a href="#">
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
