import React, { useRef, useEffect } from "react";
import "../styles/all.css";
import "../styles/progressBar.css";

const ProgressBar = ({ status }) => {
  const firstElement = useRef();
  const secondElement = useRef();
  const thirdElement = useRef();

  useEffect(() => {
    if (status === "pending") {
      firstElement.current.children[0].classList.add("circle-status-blue");
    }
    if (status === "send") {
      firstElement.current.classList.add("line-status-green");
      firstElement.current.children[0].classList.add("circle-status-green");
      firstElement.current.children[0].innerHTML = `<svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path></svg>`;
      secondElement.current.children[0].classList.add("circle-status-blue");
    }
    if (status === "delivered") {
      firstElement.current.classList.add("line-status-green");
      thirdElement.current.classList.add("line-status-green");
      firstElement.current.children[0].innerHTML = `<svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path></svg>`;
      secondElement.current.children[0].innerHTML = `<svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path></svg>`;
      thirdElement.current.children[0].innerHTML = `<svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path></svg>`;
      firstElement.current.children[0].classList.add("circle-status-green");
      secondElement.current.children[0].classList.add("circle-status-green");
      thirdElement.current.children[0].classList.add("circle-status-green");
    }
  }, []);

  return (
    <div className="d-flex">
      <div className="col-div" style={{ flex: "1" }}>
        <div ref={firstElement} className="line-1 d-flex justify-content-start">
          <span className="dot dot-1">1</span>
        </div>
      </div>
      <div className="col-div">
        <div
          ref={secondElement}
          className="line-2  d-flex justify-content-center "
        >
          <span className="dot dot-2">2</span>
        </div>
      </div>
      <div className="col-div" style={{ flex: "1" }}>
        <div ref={thirdElement} className="line-3  d-flex justify-content-end ">
          <span className="dot dot-3">3</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
