import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../styles/profil.css";
import "../../styles/all.css";
import "../../styles/modifyProduct.css";

const initialValuesImg = {
  images: [],
};

const ModifyDogImg = ({ item }) => {
  const navigate = useNavigate();

  const [msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState("");

  const paramsId = useParams();
  const token = localStorage.getItem("jwt");

  const [previewImg, setPreviewImg] = useState([]);

  const [inputFields, setInputFields] = useState([{ File }]);

  const addFormFieldsImg = () => {
    setInputFields([...inputFields, { File }]);
  };

  const removeFormFieldsImg = () => {
    const rowsBlob = [...inputFields];
    rowsBlob.splice(-1);
    setInputFields(rowsBlob);
  };

  const handleChangeImgs = (e, index) => {
    const value = e.target.files[0];
    value.blob = URL.createObjectURL(value);
    const listBlob = [...inputFields];
    listBlob[index] = value;
    setInputFields(listBlob);
  };

  const deleteImg = (value) => {
    const token = localStorage.getItem("jwt");

    axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/dog/image/${paramsId.id}`,
      data: {
        images: value,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: paramsId.id,
    })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitImg = (values, { setSubmitting, resetForm }) => {
    initialValuesImg.images.push(...inputFields);

    if (inputFields.length + previewImg.length >= 3) {
      axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_API_URL}/dog/${paramsId.id}`,
        data: values,
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        params: paramsId.id,
      })
        .then(() => {
          setSubmitting(false);
          resetForm();
          setInputFields([{ File }]);
          window.location.reload();
        })
        .catch((error) => {
          setSubmitting(false);
          resetForm();
          setInputFields([{ File }]);
          console.log(error);
        });
    } else {
      setShowMsg("Vous devez ajouter au minimum 3 images produits !");
    }
  };

  const deleteDog = () => {
    axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/dog/delete/${paramsId.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: paramsId.id,
    })
      .then((response) => {
        setMsg(response.data.message);
        navigate("/dogs");
      })
      .finally(() => {
        window.location.reload();
        // navigate(0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setInitialValues = async () => {
    if (item.images.length !== 0) {
      setPreviewImg(item.images);
    } else {
      return null;
    }
  };
  useEffect(() => {
    setInitialValues();
  }, []);

  return (
    <div className="bgcolorffffff ">
      <div className="addItemsContainer p-xl-5">
        <Formik initialValues={initialValuesImg} onSubmit={onSubmitImg}>
          {({ handleSubmit }) => (
            <Form
              className="form-container-formik  d-flex flex-column gap-3"
              onSubmit={handleSubmit}
            >
              <div className="">
                <h5 className="text-center font-size-1_6">
                  Modification des images du chien
                </h5>
              </div>
              <Form.Group className="position-relative mb-3">
                <Form.Label>Ajouter ou suprimer des images</Form.Label>
                <br />
                <span className="text-muted">
                  La première image ajoutée fera office d'image de présentation
                  du chien dans la page boutique.
                </span>
                <div className="d-flex" id="myImg">
                  <Row className="justify-content-md-center">
                    {previewImg.map((img, index) => {
                      return (
                        <Col
                          key={index}
                          className="d-flex flex-column align-items-center"
                        >
                          <div>
                            <img src={img} alt="" height="200" width="200" />
                          </div>
                          <div className="d-flex justify-content-center">
                            <Button
                              className="btn btn-danger"
                              onClick={() => deleteImg(img)}
                            >
                              suprimer
                            </Button>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
                {inputFields.map((data, index) => {
                  return (
                    <div key={index} className="my-3">
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleChangeImgs(e, index)}
                        name="images"
                        required
                      />
                      {typeof data === "string" && (
                        <img
                          src={data}
                          height="200"
                          width="200"
                          alt="image"
                        ></img>
                      )}
                      {data.name ? (
                        <div>
                          <span>
                            <img
                              src={data.blob}
                              height="200"
                              width="200"
                              alt="med1"
                            />
                          </span>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
                {inputFields.length + previewImg.length < 3 && (
                  <div>
                    <span className="text-danger">
                      Minimum 3 images requise
                    </span>
                  </div>
                )}
                <div className="d-flex gap-2">
                  <div>
                    <Button type="button" onClick={addFormFieldsImg}>
                      Ajouter une ligne
                    </Button>
                  </div>
                  <div>
                    {inputFields.length ? (
                      <Button className="btn" onClick={removeFormFieldsImg}>
                        Suprimer une ligne
                      </Button>
                    ) : null}
                  </div>
                </div>
              </Form.Group>
              <div className="d-flex justify-center">
                <span className="text-danger text-center">{showMsg}</span>
              </div>
              <div className="d-flex justify-content-center mb-5">
                <Button
                  className="borderRadius_15px"
                  variant="primary"
                  type="submit"
                >
                  Valider
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        <Row>
          {msg && (
            <Alert className="text-center" variant="success">
              {msg}
            </Alert>
          )}
        </Row>
        <Row className="pb-5">
          <Button variant="danger" onClick={deleteDog}>
            Supprimer le chien
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default ModifyDogImg;
