const CURRENCY_FORMATTER = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
  currencyDisplay: "symbol",
  useGrouping: true,
});

export function formatCurrency(stripe) {
  let priceString = stripe.toString();
  const priceLength = priceString.length;
  const dotToAdd = ".";
  priceString = priceString.split("");
  priceString.splice(priceLength - 2, 0, dotToAdd);
  let newString = priceString.join("");
  const price = parseFloat(newString);
  return CURRENCY_FORMATTER.format(price);
}

export function formatNumber(number) {
  let priceString = number.toString();
  const priceLength = priceString.length;
  const dotToAdd = ".";
  priceString = priceString.split("");
  priceString.splice(priceLength - 2, 0, dotToAdd);
  let newString = priceString.join("");
  const price = parseFloat(newString);
  return price;
}
