import React, { useState, useEffect } from "react";
import "../../styles/profil.css";
import "../../styles/all.css";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { getAnniversary } from "../../services/formatString";
import { formatCurrency } from "../../services/formatCurrency";
import Alert from "react-bootstrap/Alert";

const initialValues = {
  name: "",
  race: "",
  gender: "",
  price: 1,
  oldPrice: 0,
  anniversary: "",
  description: "",
  dogId: "",
  images: [],
  techDescription: [],
};

const initialValuesRaces = {
  race: "",
};

const ProductSchemaRaces = Yup.object().shape({
  race: Yup.string()
    .min(2, "trop petit!")
    .max(100, "trop long!")
    .required("Ce champ est obligatoire"),
});

const ProductSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "trop petit!")
    .max(300, "trop long!")
    .required("Ce champ est obligatoire"),
  race: Yup.string().required("Ce champ est obligatoire"),
  gender: Yup.string()
    .oneOf(
      ["Mâle", "Femelle"],
      "Le sexe du chien doit être l'une des valeurs suivantes: Mâle, Femelle"
    )
    .required("Ce champ est obligatoire"),
  price: Yup.number()
    .integer("Le prix doit être un nombre entier")
    .min(0, "Le prix en euro doit être supérieur ou égal à 0")
    .required("Le prix est obligatoire")
    .typeError("Saisissez le prix"),
  oldPrice: Yup.number()
    .integer("Le prix doit être un nombre entier")
    .min(0, "Le prix en euro doit être supérieur ou égal à 0")
    .typeError("Saisissez le prix"),
  anniversary: Yup.string().required("Ce champ est obligatoire"),
  description: Yup.string()
    .min(2, "trop petit!")
    .max(2000, "trop long!")
    .required("Ce champ est obligatoire"),
  dogId: Yup.string().required("Ce champ est obligatoire"),
});

export default function AddDog() {
  const [races, setRaces] = useState();
  const [loadingRaces, setLoadingRaces] = useState(false);
  useEffect(() => {
    const getRaces = async () => {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/dog/race`
      );
      setRaces(data);
      setLoadingRaces(true);
    };
    getRaces();
  }, []);
  const [msg, setMsg] = useState("");
  const [msgRaces, setMsgRaces] = useState("");
  const [showMsg, setShowMsg] = useState("");

  const [inputFields, setInputFields] = useState([{ File }]);

  const addFormFieldsImg = () => {
    setInputFields([...inputFields, { File }]);
  };

  const removeFormFieldsImg = () => {
    const rowsBlob = [...inputFields];
    rowsBlob.splice(-1);
    setInputFields(rowsBlob);
  };

  const handleChangeImgs = (e, index) => {
    const value = e.target.files[0];
    value.blob = URL.createObjectURL(value);
    const listBlob = [...inputFields];
    listBlob[index] = value;
    setInputFields(listBlob);
  };

  const [formValues, setFormValues] = useState([{ titre: "", valeur: "" }]);

  const addFormFieldsDescription = () => {
    setFormValues([...formValues, { titre: "", valeur: "" }]);
  };

  const removeFormFieldsDescription = (i) => {
    const newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleChangeValues = (e, index) => {
    const { name, value } = e.target;
    const newFormValues = [...formValues];
    newFormValues[index][name] = value;
    setFormValues(newFormValues);
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    initialValues.images.push(...inputFields);
    initialValues.techDescription.push(...formValues);
    const token = localStorage.getItem("jwt");

    if (formValues.length >= 3 && inputFields.length >= 3) {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/dog/`,
        data: {
          name: values.name,
          race: values.race,
          gender: values.gender,
          price: values.price,
          oldPrice: values.oldPrice,
          anniversary: values.anniversary,
          description: values.description,
          identificationId: values.dogId,
          images: [...values.images],
          techDescription: [...values.techDescription],
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
        .then((response) => {
          setSubmitting(false);
          resetForm();
          setInputFields([{ File }]);
          setFormValues([{ titre: "", valeur: "" }]);
          initialValues.images = [];
          initialValues.techDescription = [];
          setMsg(response.data.message);
        })
        .catch((error) => {
          setSubmitting(false);
          resetForm();
          setInputFields([{ File }]);
          setFormValues([{ titre: "", valeur: "" }]);
          initialValues.images = [];
          initialValues.techDescription = [];
          setMsg(error.data.message);
          console.log(error);
        });
    } else {
      setShowMsg(
        "Vous devez ajouter au minimum 3 images produits et 3 descriptions techniques !"
      );
    }
  };

  const onSubmitRaces = (values, { setSubmitting, resetForm }) => {
    const token = localStorage.getItem("jwt");

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/dog/race`,
      data: values,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setSubmitting(false);
        resetForm();
        setMsgRaces(response.data.message);
      })
      .catch((error) => {
        setSubmitting(false);
        resetForm();
        setMsgRaces(error.data.message);
        console.log(error);
      });
  };

  return (
    <div className="bgcolorffffff p-xl-5">
      <div className="addItemsContainer p-xl-5">
        <Formik
          initialValues={initialValues}
          validationSchema={ProductSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form
              className="form-container-formik d-flex flex-column gap-3"
              onSubmit={handleSubmit}
            >
              <div className="m-5">
                <h5 className="text-center font-size-1_6">
                  Formulaire d'ajouts d'un chien
                </h5>
              </div>
              <Form.Group
                controlId="validationFormik101"
                className="position-relative"
              >
                <Form.Label>Nom du Chien</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name}
                  onBlur={handleBlur}
                  required
                />
                <ErrorMessage
                  component="span"
                  name="name"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group
                controlId="validationFormik101"
                className="position-relative"
              >
                <Form.Label>Race du Chien</Form.Label>
                {loadingRaces ? (
                  <Form.Select
                    type="text"
                    name="race"
                    value={values.race}
                    onChange={handleChange}
                    isValid={touched.race && !errors.race}
                    isInvalid={!!errors.race}
                    onBlur={handleBlur}
                    required
                  >
                    {races.map((race, index) => (
                      <option key={index} value={race.name}>
                        {race.name}
                      </option>
                    ))}
                  </Form.Select>
                ) : null}
                <ErrorMessage
                  component="span"
                  name="race"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Sexe du chien</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  isValid={touched.gender && !errors.gender}
                  isInvalid={!!errors.gender}
                  onBlur={handleBlur}
                  required
                >
                  <option>Sélectionner le sexe du chien</option>
                  <option value="Mâle">Mâle</option>
                  <option value="Femelle">Femelle</option>
                </Form.Select>
                <ErrorMessage
                  component="span"
                  name="gender"
                  className="text-danger"
                />
              </Form.Group>

              <div>
                <Form.Label>Prix du Chien</Form.Label>
                <InputGroup className="position-relative">
                  <InputGroup.Text>Prix</InputGroup.Text>
                  <Form.Control
                    className="inputPrice"
                    type="number"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                    isValid={touched.price && !errors.price}
                    isInvalid={!!errors.price}
                    onBlur={handleBlur}
                    required
                  />
                  <InputGroup.Text>
                    {formatCurrency(values.price)}
                  </InputGroup.Text>
                </InputGroup>

                <ErrorMessage
                  component="span"
                  name="price"
                  className="text-danger"
                />
              </div>
              <div>
                <Form.Label>Prix du Chien avant promotion</Form.Label>
                <br />
                <Form.Text>
                  La valeur indiquée ci-dessous indique le prix du chien avant
                  la promotion. <br /> Si la valeur est à 0, l'ancien prix ne
                  sera pas affiché.
                </Form.Text>
                <InputGroup className="position-relative">
                  <InputGroup.Text>Prix</InputGroup.Text>
                  <Form.Control
                    className="inputPrice"
                    type="number"
                    name="oldPrice"
                    value={values.oldPrice}
                    onChange={handleChange}
                    isValid={touched.oldPrice && !errors.oldPrice}
                    isInvalid={!!errors.oldPrice}
                    onBlur={handleBlur}
                    required
                  />
                  <InputGroup.Text className="discount">
                    {formatCurrency(values.oldPrice)}
                  </InputGroup.Text>
                </InputGroup>
                <ErrorMessage
                  component="span"
                  name="oldPrice"
                  className="text-danger"
                />
              </div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Date de naissance du chien</Form.Label>
                <Form.Control
                  type="date"
                  name="anniversary"
                  value={values.anniversary}
                  onChange={handleChange}
                  isValid={touched.anniversary && !errors.anniversary}
                  isInvalid={!!errors.anniversary}
                  onBlur={handleBlur}
                  required
                  rows={1}
                />
                <div>
                  <span className="p-2">
                    <i className="fas fa-birthday-cake"> : </i>
                  </span>
                  <Form.Text>{getAnniversary(values.anniversary)}</Form.Text>
                </div>
                <ErrorMessage
                  component="span"
                  name="anniversary"
                  className="text-danger"
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Déscription du chien</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  isValid={touched.description && !errors.description}
                  isInvalid={!!errors.description}
                  onBlur={handleBlur}
                  as="textarea"
                  required
                  rows={5}
                />
                <ErrorMessage
                  component="span"
                  name="description"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Numéro d'identification du chien</Form.Label>
                <Form.Control
                  type="text"
                  name="dogId"
                  value={values.dogId}
                  onChange={handleChange}
                  isValid={touched.dogId && !errors.dogId}
                  isInvalid={!!errors.dogId}
                  onBlur={handleBlur}
                  as="textarea"
                  required
                  rows={1}
                />
                <ErrorMessage
                  component="span"
                  name="dogId"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group className="position-relative mb-3">
                <Form.Label>Ajouter toutes les images du chien</Form.Label>
                <br />
                <span className="text-muted">
                  La première image ajoutée fera office d'image de présentation
                  du chien dans la page boutique.
                </span>
                {inputFields.map((data, index) => {
                  return (
                    <div key={index} className="my-3">
                      <Form.Control
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => handleChangeImgs(e, index)}
                        name="images"
                        onBlur={handleBlur}
                        required
                      />
                      {data.name ? (
                        <div>
                          <span>
                            <img
                              src={data.blob}
                              height="200"
                              width="200"
                              alt="med1"
                            />
                          </span>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
                {inputFields.length < 3 && (
                  <div>
                    <span className="text-danger">
                      Minimum 3 images requise
                    </span>
                  </div>
                )}
                <div className="d-flex gap-2">
                  <div>
                    <Button type="button" onClick={addFormFieldsImg}>
                      Ajouter une ligne
                    </Button>
                  </div>
                  <div>
                    {inputFields.length ? (
                      <Button className="btn" onClick={removeFormFieldsImg}>
                        Suprimer une ligne
                      </Button>
                    ) : null}
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Description technique</Form.Label>
                {formValues.map((element, i) => {
                  return (
                    <div key={i}>
                      <div className="d-flex justify-content-center gap-3">
                        <Form.Control
                          type="text"
                          name="titre"
                          onChange={(e) => handleChangeValues(e, i)}
                          value={element.titre}
                          onBlur={handleBlur}
                          required
                        />
                        <Form.Control
                          type="text"
                          name="valeur"
                          onChange={(e) => handleChangeValues(e, i)}
                          value={element.valeur}
                          onBlur={handleBlur}
                          required
                        />
                        {i ? (
                          <Button
                            onClick={() => removeFormFieldsDescription(i)}
                          >
                            Suprimer ligne
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  );
                })}

                {formValues.length < 3 && (
                  <div>
                    <span className="text-danger">
                      Minimum 3 descriptions techniques requise
                    </span>
                  </div>
                )}

                <div>
                  <Button
                    type="button"
                    onClick={() => addFormFieldsDescription()}
                  >
                    Ajouter une ligne
                  </Button>
                </div>
              </Form.Group>
              <div className="d-flex justify-center">
                <span className="text-danger text-center">{showMsg}</span>
              </div>
              {msg && (
                <Alert className="text-center" variant="success">
                  {msg}
                </Alert>
              )}
              <div className="d-flex justify-content-center m-5">
                <Button
                  className="borderRadius_15px"
                  variant="primary"
                  type="submit"
                >
                  Valider
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div>
          <div className="text-center p-5">
            <h3>Ajouter une race de chien dans la base de données</h3>
          </div>
          <Formik
            initialValues={initialValuesRaces}
            validationSchema={ProductSchemaRaces}
            onSubmit={onSubmitRaces}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => (
              <Form
                className="form-container-formik d-flex flex-column gap-3"
                onSubmit={handleSubmit}
              >
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label>Race de chien</Form.Label>
                  <Form.Control
                    type="text"
                    name="race"
                    value={values.race}
                    onChange={handleChange}
                    isValid={touched.race && !errors.race}
                    isInvalid={!!errors.race}
                    onBlur={handleBlur}
                    required
                  />
                  <ErrorMessage
                    component="span"
                    name="race"
                    className="text-danger"
                  />
                </Form.Group>
                {msgRaces && (
                  <Alert className="text-center" variant="success">
                    {msgRaces}
                  </Alert>
                )}
                <div className="d-flex justify-content-center m-5">
                  <Button
                    className="borderRadius_15px"
                    variant="primary"
                    type="submit"
                  >
                    Valider
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
