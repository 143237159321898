import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import "../styles/profil.css";
import "../styles/all.css";
import useDocumentTitle from "../services/documentTitle";

const Backoffice = () => {
  useDocumentTitle("Backoffice");
  const page = useLocation();

  return (
    <div className="bgcolorffffff">
      <div className="vh-40 d-flex justify-content-center align-items-center">
        <div className="">
          <h1 className="text-center p-5 m-5">Page de Backoffice</h1>
        </div>
      </div>
      <div>
        <div>
          <Nav fill variant="pills" defaultActiveKey={page.pathname}>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/backoffice/addProduct"
                className="nav-link-profil"
                eventKey="/backoffice/addProduct"
              >
                Ajouter un produit
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/backoffice/addDog"
                className="nav-link-profil"
                eventKey="/backoffice/addDog"
              >
                Ajouter un chien
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/backoffice/order"
                className="nav-link-profil"
                eventKey="/backoffice/order"
              >
                Gérer les commandes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/backoffice/refund"
                className="nav-link-profil"
                eventKey="/backoffice/refund"
              >
                Gérer les retours
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default Backoffice;
