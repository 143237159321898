import React, { useContext } from "react";
import "../styles/navbar.css";
import websiteLogo from "../images/animal-cachorro-dog-svgrepo-com.svg";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../services/authProvider.jsx";
import { useShoppingCart } from "../services/shoppingCartContext.jsx";

const NavBar = () => {
  const ctx = useContext(AuthContext);

  const { cartQuantity, openCart } = useShoppingCart();

  const logOutNavigate = useNavigate();

  function logOut() {
    localStorage.removeItem("jwt");
    logOutNavigate("/");
    window.location.reload();
  }

  return (
    <nav className="navbar fixed-top navbar-expand-lg bgcolor1D1818">
      <div className="container-fluid">
        <div id="logo-toggle">
          <Link to="/">
            <img src={websiteLogo} alt="logo" height="50" />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse w-25 logo-nametag"
          id="navbarTogglerDemo03"
        >
          <Link className="px-2" to="/">
            <img src={websiteLogo} alt="logo" height="50" />
          </Link>
          <Link className="nav-link fontMontserrat colorffffff" to="/">
            Site - Canin
          </Link>
        </div>
        <div
          className="collapse navbar-collapse justify-content-center w-50"
          id="navbarTogglerDemo03"
        >
          <ul className="navbar-nav mb-2 mb-lg-0 align-items-center">
            <li className="nav-item dropdown">
              <Link className="nav-link fontMontserrat" to="/pension">
                Pension
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fontMontserrat" to="/dogs">
                Chiens
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/store" className="nav-link fontMontserrat">
                Boutique
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/dressage" className="nav-link fontMontserrat">
                Dressage
              </Link>
            </li>
          </ul>
        </div>
        <div
          className="collapse navbar-collapse justify-content-end w-25"
          id="navbarTogglerDemo03"
        >
          <ul className="navbar-nav mb-2 mb-lg-0 align-items-center">
            <li className="nav-item">
              <Link to="/contact" className="nav-link fontMontserrat">
                Contact
              </Link>
            </li>
            <li className="nav-item dropdown text-center w-100">
              <a
                className="nav-link "
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-expanded="false"
              >
                <i className="far fa-user-circle"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                {ctx ? (
                  <div className="text-center">
                    <li className="nav-link">
                      <button
                        onClick={logOut}
                        className="dropdown-item logout-button"
                      >
                        Déconnexion
                      </button>
                    </li>
                    <li className="nav-link">
                      <Link className="dropdown-item" to="/profil/info">
                        Profil
                      </Link>
                    </li>
                    {ctx.isAdmin ? (
                      <li className="nav-link">
                        <Link
                          className="dropdown-item"
                          to="/backoffice/addProduct"
                        >
                          Admin
                        </Link>
                      </li>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <li className="nav-link">
                      <Link className="dropdown-item" to="/login">
                        Connexion
                      </Link>
                    </li>
                    <li className="nav-link">
                      <Link className="dropdown-item" to="/signup">
                        Inscription
                      </Link>
                    </li>
                  </div>
                )}
              </ul>
            </li>
            <li className="nav-item" onClick={openCart} role="button">
              <a className="nav-link" id="inscription-link">
                <i className="fa-solid fa-cart-shopping"></i>
              </a>
              {cartQuantity > 0 && (
                <div
                  className="rounded-circle bg-danger d-flex justify-content-center align-items-center"
                  style={{
                    color: "white",
                    width: "1.4rem",
                    height: "1.4rem",
                    position: "absolute",
                    bottom: "0",
                    transform: "translate(58%, -5%)",
                  }}
                >
                  {cartQuantity}
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
