import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../styles/login.css";
import "../styles/all.css";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import useDocumentTitle from "../services/documentTitle";

const initialValues = {
  email: "",
  password: "",
};

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("email invalide")
    .required("Ce champ est obligatoire"),
  password: Yup.string()
    .required("Mot de passe est obligatoire")
    .min(8, "Mot de passe doit être plus grand que 8 caractères")
    .max(50, "Mot de passe doit être plus petit que 50 caractères"),
});

const Login = () => {
  useDocumentTitle("Connexion");

  const [msg, setMsg] = useState("");

  const [passwordType1, setPasswordType1] = useState("password");

  const navigate = useNavigate();
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/auth/login`,
      data: values,
    })
      .then((response) => {
        localStorage.setItem("jwt", response.data.token);
        navigate("/", { replace: true });
        setSubmitting(false);
        resetForm();
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setMsg(error.response.data.message);
        } else if (error.response.status === 429) {
          setMsg(error.response.data);
        }
        setSubmitting(false);

        console.log(error);
      });
  };

  return (
    <div className="bgcolorffffff p-lg-5 vh-100">
      <h1 className="text-center p-5 m-5">Connectez-vous</h1>
      <div className="form-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={LoginSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form className="" onSubmit={handleSubmit}>
              <Form.Group className="my-3" controlId="formBasicEmail">
                <Form.Label className="font-size-1_2">
                  Adresse e-mail
                </Form.Label>
                <Form.Control
                  autoComplete="email"
                  required
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Entrez votre adresse e-mail."
                  isValid={touched.email && !errors.email}
                  isInvalid={!!errors.email}
                />
                <ErrorMessage
                  component="span"
                  name="email"
                  className="text-danger"
                />
              </Form.Group>

              <Form.Group className="mt-4" controlId="formBasicPassword">
                <Form.Label className="font-size-1_2">Mot de passe</Form.Label>
                <InputGroup>
                  <Form.Control
                    autoComplete="current-password"
                    required
                    name="password"
                    type={passwordType1}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Entrez votre mot de passe."
                    isValid={touched.password && !errors.password}
                    isInvalid={!!errors.password}
                  />
                  <InputGroup.Text
                    onClick={() =>
                      passwordType1 === "password"
                        ? setPasswordType1("text")
                        : setPasswordType1("password")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {passwordType1 === "password" ? (
                      <i className="fa-regular fa-eye-slash"></i>
                    ) : (
                      <i className="fa-regular fa-eye"></i>
                    )}
                  </InputGroup.Text>
                </InputGroup>
                <div>
                  <Form.Text className="text-muted font-size-1_2">
                    Nous ne partagerons jamais votre mot de passe avec qui que
                    ce soit d'autre.
                  </Form.Text>
                </div>
                <ErrorMessage
                  component="span"
                  name="password"
                  className="text-danger"
                />
              </Form.Group>
              <div className="text-center">
                {msg && <Alert variant="danger">{msg}</Alert>}
              </div>
              <div className="d-flex justify-content-center m-5">
                <Button
                  className="borderRadius_15px"
                  variant="primary"
                  type="submit"
                >
                  Connexion
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="text-center">
          <Link to="/reset">
            <span>Mot de passe oublié ?</span>
          </Link>
        </div>
      </div>
      <div className="d-flex div-border-wrapper">
        <div></div>
        <div>
          <span className="mx-5">ou</span>
        </div>
        <div></div>
      </div>
      <div className="d-flex justify-content-center">
        <Link to="/signup">
          <Button className="borderRadius_15px" variant="primary" type="button">
            S'inscrire
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Login;
