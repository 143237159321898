import React from "react";
import "../styles/all.css";
import "../styles/pension.css";
import { Link } from "react-router-dom";
import useDocumentTitle from "../services/documentTitle";
import LogoPension1 from "../images/pension/pension-logo-1.svg";
import LogoPension2 from "../images/pension/pension-logo-2.svg";
import LogoPension3 from "../images/pension/pension-logo-3.svg";
import LogoPension4 from "../images/pension/pension-logo-4.svg";

const Pension = () => {
  useDocumentTitle("Pension");

  return (
    <div className="bgcolor1D1818" style={{ paddingTop: "7vh" }}>
      <div className="header-pension">
        <div className="pension-div-text ">
          <div className="colorffffff p-3 ">
            <div className="">
              <h1 className="display-4 fontRedHat">
                Garderie et pension canine.
              </h1>
            </div>
            <div className="">
              <p className="font-size-1_2 fontLato">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque euismod iaculis libero, ac ultrices justo
                ullamcorper at. Nulla tempor lacinia imperdiet. Sed in sodales
                erat, id finibus elit.
              </p>
            </div>
          </div>
        </div>
        <div className="custom-shape-divider-bottom-1667567902">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section-pension">
        <div className="vertical-div-wave">
          <div></div>
        </div>
        <div className="vertical-div-text">
          <div>
            <h3 className="display-4 fontComfortaa color2E8B57">
              Notre centre
            </h3>
          </div>
          <div>
            <p className="font-size-1_2 fontLato">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in
              reprehenderit in
            </p>
          </div>
          <div className="py-5">
            <h3 className="color9e9e9e">Activité d'un chien chez nous</h3>
            <div className="pension-div-logo-wrapper">
              <div>
                <img src={LogoPension1} alt="" />
                <div>
                  <h5 className="color2E8B57">Duis aute irure</h5>
                  <p className="font-size-1">
                    Arcu dui vivamus arcu felis bibendum ut tempus imperd.
                  </p>
                </div>
              </div>
              <div>
                <img src={LogoPension2} alt="" />
                <div>
                  <h5 className="color2E8B57">Duis aute irure</h5>
                  <p className="font-size-1">
                    Arcu dui vivamus arcu felis bibendum ut tempus imperd.
                  </p>
                </div>
              </div>
              <div>
                <img src={LogoPension3} alt="" />
                <div>
                  <h5 className="color2E8B57">Duis aute irure</h5>
                  <p className="font-size-1">
                    Arcu dui vivamus arcu felis bibendum ut tempus imperd.
                  </p>
                </div>
              </div>
              <div>
                <img src={LogoPension4} alt="" />
                <div>
                  <h5 className="color2E8B57">Duis aute irure</h5>
                  <p className="font-size-1">
                    Arcu dui vivamus arcu felis bibendum ut tempus imperd.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="pension-section-tarif-header-bg">
        <div>
          <div>
            <h3 className="color2E8B57 fontComfortaa display-5 ">
              TARIFS PENSION CANINE
            </h3>
          </div>
          <div>
            <p className="font-size-1_1 colorffffff ">
              habitant quisque sed in at. Felis blandit sit aliquam dignissim
              porta sagittis enim nec vestibulum.
            </p>
          </div>
        </div>
      </div>
      <section className="pension-section-tarif py-4 p-md-5">
        <div className="pension-section-tarif-text p-3 p-md-5">
          <div className="pension-section-tarif-header">
            <div>
              <h3 className="color2E8B57 fontComfortaa display-5 ">
                TARIFS PENSION CANINE
              </h3>
            </div>
            <div>
              <p className="font-size-1_1  ">
                habitant quisque sed in at. Felis blandit sit aliquam dignissim
                porta sagittis enim nec vestibulum.
              </p>
            </div>
          </div>

          <div className="pension-section-tarif-table">
            <table className="table  table-striped">
              <thead className="font-size-1_1 fontRubik">
                <tr>
                  <th>Nombre de chiens</th>
                  <th>Formules</th>
                  <th>Tarifs</th>
                </tr>
              </thead>
              <tbody className="font-size-0_9 fontOutfit">
                <tr className="">
                  <td>1 chien</td>
                  <td>Pension</td>
                  <td>15 € / 24h</td>
                </tr>
                <tr className="">
                  <td>2 chiens</td>
                  <td>Pension dans le même box</td>
                  <td>23 € / 24h</td>
                </tr>
                <tr className="">
                  <td>3 chiens</td>
                  <td>Pension dans le même box</td>
                  <td>30 € / 24h</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className=" p-5">
            <div className="d-flex flex-column gap-3 text-center">
              <div>
                <h3 className=" color2E8B57">Nous contacter</h3>
              </div>
              <div className="d-block d-xl-flex justify-content-around gap-3">
                <div>
                  <i className="fa-solid fa-phone-flip color2E8B57 font-size-1_3  p-2"></i>
                  <span className="font-size-1_1 fontOutfit">
                    {" "}
                    06 44 06 06 06
                  </span>
                </div>
                <div>
                  <i className="fa-solid fa-envelope color2E8B57 font-size-1_3 p-2"></i>
                  <span className="font-size-1_1 fontOutfit">
                    {" "}
                    bonjour@hotmail.com
                  </span>
                </div>
              </div>
              <div className="p-3">
                <Link to="/contact">
                  <button
                    type="button"
                    className="btn fontOutfit btn-pension-contact"
                  >
                    CONTACT
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="pension-section-tarif-img">
          <div></div>
        </div>
      </section>
    </div>
  );
};

export default Pension;
