import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import "../../styles/all.css";
import "../../styles/boutique.css";
import Pagination from "../pagination";
import { formatCurrency } from "../../services/formatCurrency";
import { truncateName } from "../../services/formatString";
import { useShoppingCart } from "../../services/shoppingCartContext.jsx";

const Accessories = ({ json, radioValue, sortType, searchInput }) => {
  const myRef = useRef();

  const [produits, setProduits] = useState([]);

  const navigateToProduct = useNavigate();

  const goToProduct = (id) => {
    navigateToProduct(`/store/product/${id}`);
  };

  const { increaseCartQuantity } = useShoppingCart();

  const sortByCategoryFc = () => {
    if (radioValue === "1") {
      let accessory = "Accessoires";
      return json.filter((o) => o.category === accessory);
    }
    if (radioValue === "2") {
      let jouets = "Jouets";
      return json.filter((o) => o.category === jouets);
    }
    if (radioValue === "3") {
      let food = "Nourritures";
      return json.filter((o) => o.category === food);
    }
    if (radioValue === "4") {
      return json;
    } else return json;
  };

  const sortByFc = (result1) => {
    if (sortType === "0") {
      return result1;
    }
    if (sortType === "1") {
      return [...result1].sort((a, b) => a.price - b.price);
    }
    if (sortType === "2") {
      return [...result1].sort((a, b) => b.price - a.price);
    }
    if (sortType === "3") {
      return [...result1].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else return result1;
  };

  const searchFunction = (result2) => {
    if (searchInput !== "") {
      return result2.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
    } else {
      return result2;
    }
  };

  useEffect(
    (json) => {
      const result1 = sortByCategoryFc(json);
      const result2 = sortByFc(result1);
      const result3 = searchFunction(result2);
      setProduits(result3);
    },
    [sortType, radioValue, searchInput]
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage] = useState(12);

  const indexOfLastProduct = currentPage * productPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productPerPage;

  const currentProduct = produits.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="page-products-bg" ref={myRef}>
      {currentProduct.length > 0 ? (
        <div className="containerSection">
          <Container>
            <div className="justify-content-md-center currentProductRow">
              {currentProduct.map((produit) => (
                <div
                  key={produit._id}
                  className="d-flex flex-column align-items-center products-col "
                >
                  <div
                    className="imgDivWrapper"
                    onClick={() => goToProduct(produit._id)}
                  >
                    <img src={produit.images[0]} alt="" />
                  </div>
                  <div className="textDivWrapper">
                    <h5 className="color000000 font-size-1_4 fontLato text-center">
                      {truncateName(produit.name)}
                    </h5>
                    <p className="color1F8ADD font-size-1_2 fontMontserrat text-center">
                      {formatCurrency(produit.price)}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "0.5em",
                      }}
                    >
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={() => goToProduct(produit._id)}
                      >
                        VOIR PRODUIT
                      </Button>
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={() => increaseCartQuantity(produit._id)}
                      >
                        AJOUTER AU PANIER
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Container>
          <div className="d-flex justify-content-center paginationDiv">
            <Pagination
              productPerPage={productPerPage}
              totalProducts={produits.length}
              paginate={paginate}
              currentPage={currentPage}
              myRef={myRef}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="vh-30 d-flex justify-content-center align-items-center">
            <div className="color000000">
              <h1>Aucun produit ne peut être affiché</h1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Accessories;
