import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/navbar";
import Home from "./pages/accueil";
import Login from "./pages/login";
import Page404 from "./pages/404";
import ResetMdp from "./pages/resetMdp";
import SignUp from "./pages/inscription";
import Backoffice from "./pages/backoffice";
import Profil from "./pages/profil";
import Boutique from "./pages/boutique";
import Contact from "./pages/contact";
import Product from "./components/boutique/product";
import Dressage from "./pages/dressage";
import MyInfo from "./components/profil/info";
import CartInfo from "./components/profil/cartInfo";
import MyOrder from "./components/profil/myOrder";
import AddProduct from "./components/backoffice/addProduct";
import AddDog from "./components/backoffice/addDog";
import { AuthContext } from "./services/authProvider";
import { ShoppingCartProvider } from "./services/shoppingCartContext.jsx";
import BackofficeModify from "./components/backoffice/backofficeModify";
import EmailVerify from "./pages/emailVerify";
import SuccessPayment from "./components/boutique/successPayment";
import Dogs from "./pages/chiens";
import Dog from "./components/chiens/dog";
import BackofficeModifyDog from "./components/backoffice/backofficeModifyDog";
import Order from "./components/backoffice/order";
import Pension from "./pages/pension";
import ScrollToTop from "./services/scrollToTop";
import OrderTrack from "./components/profil/orderTrack";
import Refund from "./components/profil/refund";
import RefundAdmin from "./components/backoffice/refund";
import Footer from "./components/footer";
import ChangePassword from "./pages/changePassword";

const App = () => {
  const ctx = useContext(AuthContext);

  return (
    <div className="App">
      <ShoppingCartProvider>
        <NavBar />
        <ScrollToTop>
          <Routes>
            {ctx ? (
              <>
                {ctx.isAdmin ? (
                  <>
                    <Route path="/backoffice" element={<Backoffice />}>
                      <Route
                        path="/backoffice/addProduct"
                        element={<AddProduct />}
                      />
                      <Route path="/backoffice/addDog" element={<AddDog />} />
                      <Route path="/backoffice/order" element={<Order />} />
                      <Route
                        path="/backoffice/refund"
                        element={<RefundAdmin />}
                      />
                    </Route>
                    <Route
                      path="/backoffice/modifyProduct/:id"
                      element={<BackofficeModify />}
                    />
                    <Route
                      path="/backoffice/modifyDog/:id"
                      element={<BackofficeModifyDog />}
                    />
                  </>
                ) : null}
                <Route path="/profil" element={<Profil />}>
                  <Route path="/profil/info" element={<MyInfo />} />
                  <Route path="/profil/cartInfo" element={<CartInfo />} />
                  <Route path="/profil/myOrder" element={<MyOrder />} />
                </Route>
                <Route path="/progress-tracker/:id" element={<OrderTrack />} />
                <Route path="/refund/:id" element={<Refund />} />

                <Route path="/success" element={<SuccessPayment />} />
              </>
            ) : (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />

                <Route
                  path="/users/:id/verify/:token"
                  element={<EmailVerify />}
                />
              </>
            )}
            <Route path="/" element={<Home />} />
            <Route path="/pension" element={<Pension />} />
            <Route path="/dogs" element={<Dogs />} />
            <Route path="/dogs/:id" element={<Dog />} />
            <Route path="/store" element={<Boutique />} />
            <Route path="/store/product/:id" element={<Product />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/dressage" element={<Dressage />} />
            <Route path="/reset" element={<ResetMdp />} />
            <Route
              path="/users/:id/change-password/:token"
              element={<ChangePassword />}
            />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </ScrollToTop>
        <Footer />
      </ShoppingCartProvider>
    </div>
  );
};

export default App;
