import React, { useEffect, useState } from "react";
import "../../styles/all.css";
import "../../styles/profil.css";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import RefundDashboard from "./refundDashboard";

const RefundAdmin = () => {
  const [json, setJson] = useState();
  const [refundLoading, setRefundLoading] = useState(false);

  const token = localStorage.getItem("jwt");

  const [searchInput, setSearchInput] = useState("");

  const [checked, setChecked] = useState(0);
  const [orderCheckbox] = useState([]);

  const radioCheckbox = [
    { name: "Commandes remboursées", value: "Remboursées" },
    { name: "En attente", value: "En attente" },
  ];

  useEffect(() => {
    function getAllRefunds() {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/order/getallrefund`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setJson(response.data);
          setRefundLoading(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    getAllRefunds();
  }, []);
  return (
    <div className="bgcolorffffff p-xl-5">
      <div className="p-xl-5">
        <div>
          <div className="m-5">
            <h5 className="text-center font-size-1_6">
              Gestion des retours clients
            </h5>
          </div>
          <div className="p-md-5">
            <section className="search-container-section-order">
              <div>
                <Form.Control
                  style={{ backgroundColor: "white" }}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="font-size-1_2 "
                  placeholder="Search..."
                  type="text"
                  id="inputFilter"
                  aria-describedby="textFilter"
                />
              </div>
            </section>

            <div className="p-3">
              {radioCheckbox.map((check, index) => (
                <div key={index}>
                  <Form.Check
                    className="font-size-1_2 "
                    value={check.value}
                    type="checkbox"
                    id={`default-checkbox`}
                    label={check.name}
                    onClick={(e) => {
                      e.target.checked
                        ? orderCheckbox.push(e.target.value)
                        : orderCheckbox.splice(
                            orderCheckbox.indexOf(e.target.value),
                            1
                          );
                      setChecked(checked + 1);
                    }}
                  />
                </div>
              ))}
            </div>

            <div>
              {refundLoading ? (
                <div className="p-4">
                  <RefundDashboard
                    json={json}
                    searchInput={searchInput}
                    orderCheckbox={orderCheckbox}
                    checked={checked}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center vh-25 align-items-center">
                  <ThreeDots color="#00BFFF" height={80} width={80} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundAdmin;
