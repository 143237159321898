import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AuthContext } from "../../services/authProvider.jsx";
import { useShoppingCart } from "../../services/shoppingCartContext.jsx";
import "../../styles/all.css";
import "../../styles/product.css";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { formatCurrency } from "../../services/formatCurrency";
import ModalImage from "./modalImage";
import { truncateName } from "../../services/formatString";
import useDocumentTitle from "../../services/documentTitle";

const Product = () => {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});

  const paramsId = useParams();

  const [error, setError] = useState("");

  useEffect(() => {
    const getOneProduct = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/stuff/${paramsId.id}`)
        .then((response) => {
          setItem(response.data);
          setLoading(true);
        })
        .catch(() => {
          setError("Une erreur c'est produite, le produit n'existe pas !");
        });
    };

    getOneProduct();
  }, [paramsId]);

  return (
    <div style={{ paddingTop: "7vh" }} className=" mainBody">
      {loading ? (
        <div>
          {item ? (
            <CurrentProduct item={item} />
          ) : (
            <div
              style={{ height: "90vh" }}
              className="d-flex justify-content-center align-items-center"
            >
              <h1 className="text-center">Ce produit n'existe plus 😕</h1>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center vh-25 align-items-center">
          {!error ? (
            <ThreeDots color="#00BFFF" height={80} width={80} />
          ) : (
            <div
              style={{ height: "90vh" }}
              className="d-flex justify-content-center align-items-center"
            >
              <h1 className="text-center">{error}</h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Product;

export function CurrentProduct({ item }) {
  const [loadingProducts, setLoadingProducts] = useState();
  const [itemProduct, setItemProduct] = useState();

  const ctx = useContext(AuthContext);

  const paramsId = useParams();

  const navigateToProduct = useNavigate();
  const goToProduct = (id) => {
    navigateToProduct(`/store/product/${id}`);
    window.location.reload();
  };

  useEffect(() => {
    const getProducts = async () => {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/stuff/`);

      data.splice(
        data.findIndex((i) => i._id === paramsId.id),
        1
      );

      let finalProducts = data.sort(() => 0.5 - Math.random()).slice(0, 4);

      setItemProduct(finalProducts);
      setLoadingProducts(true);
    };
    getProducts();
    setMainImage("");
  }, []);

  const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity } =
    useShoppingCart();
  const quantity = getItemQuantity(item._id);

  const [mainImage, setMainImage] = useState();
  const handleImages = (src) => {
    setMainImage(src);
  };

  const [modalShow, setModalShow] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const [msg, setMsg] = useState();

  useDocumentTitle(`${item.name} - Boutique`);

  const orderNow = async (id) => {
    const token = localStorage.getItem("jwt");
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/stuff/stripe`,
      data: {
        cartItems: [{ id: id, quantity: 1 }],
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) return (window.location = res.data.url);
        return res.json().then((json) => Promise.reject(json));
      })
      .catch((e) => {
        console.error(e.error);
      });
  };

  return (
    <div>
      <div>
        <ModalImage
          show={modalShow}
          imgSrc={imgSrc}
          onHide={() => setModalShow(false)}
        />
        <div
          style={{
            width: "100%",
            height: "20vh",
            backgroundColor: "#007ddd",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <div className="fontRedHatDisplay colorffffff">
            <h1 className="fontMontserrat">Détails du produit</h1>
          </div>
          <div className="detailsProdLink">
            <Link
              style={{ textDecoration: "none" }}
              className="fontRedHat colorffffff font-size-1"
              to="/"
            >
              Accueil
            </Link>
            <span className="fontRedHat colorffffff font-size-1">{">"}</span>
            <Link
              style={{ textDecoration: "none" }}
              className="fontRedHat colorffffff font-size-1"
              to="/store"
            >
              Boutique
            </Link>
            <span className="fontRedHat colorffffff font-size-1">{">"}</span>
            <span className="fontRedHat colorffffff font-size-1">
              {truncateName(item.name)}
            </span>
          </div>
        </div>
        <section className="sectionMainProduct">
          <div className="d-flex flex-column flex-xl-row">
            <div className="bodyImageProduct">
              <div className="column-picture-div">
                {item.images.map((image, index) => {
                  return (
                    <div key={index} className="other-picture-div">
                      <img
                        src={image}
                        alt=""
                        onClick={(e) =>
                          handleImages(e.target.getAttribute("src"))
                        }
                        className="product-img"
                      />
                    </div>
                  );
                })}
              </div>

              <div className="main-picture-div">
                {!mainImage ? (
                  <img
                    src={item.images[0]}
                    alt=""
                    className="product-img"
                    onClick={(e) => {
                      setModalShow(true);
                      setImgSrc(e.target.src);
                    }}
                  />
                ) : (
                  <img
                    src={mainImage}
                    alt=""
                    className="product-img"
                    onClick={(e) => {
                      setModalShow(true);
                      setImgSrc(e.target.src);
                    }}
                  />
                )}
              </div>
            </div>

            <div className=" bodyTextProduct">
              <div>
                <span className="color000000 font-size-1_8 fontMontserrat">
                  {item.name}
                </span>
              </div>
              <hr />
              <div>
                <span className="color1F8ADD font-size-1_7  fontMontserrat">
                  {formatCurrency(item.price)}
                </span>
              </div>
              {item.oldPrice > 0 ? (
                <div>
                  <span className="font-size-1_3 discount fontLato">
                    {formatCurrency(item.oldPrice)}
                  </span>
                </div>
              ) : null}
              {ctx && (
                <div className="mb-3">
                  <Link to={`/backoffice/modifyProduct/${item._id}`}>
                    <Button className="btn btn-danger">Modifier</Button>
                  </Link>
                </div>
              )}
              {item.rating > 0 ? (
                <div>
                  <i style={{ color: "#FFA41C" }} className="fas fa-star"></i>
                </div>
              ) : null}
              <div>
                <div>
                  <span className="color000000 fontMontserrat font-size-1_2">
                    Description
                  </span>
                </div>
                <hr />
                <div>
                  <p className="color000000 font-size-1_2 product-description">
                    {item.description}
                  </p>
                </div>
              </div>
              <div>
                <span className="color5287c0">{item.quentity} en stock</span>
              </div>
              <div className="d-flex">
                <div className="btn-add-cart-container">
                  <Button
                    className="button-shop-minus"
                    onClick={() => decreaseCartQuantity(item._id)}
                  >
                    -
                  </Button>
                  <Button disabled className="button-shopView button-shop">
                    {quantity}
                  </Button>
                  <Button
                    className="button-shop-plus"
                    onClick={() => increaseCartQuantity(item._id)}
                  >
                    +
                  </Button>
                </div>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => increaseCartQuantity(item._id)}
                  >
                    Ajouter au panier
                  </Button>
                </div>
              </div>
              <div>
                <span className="color000000">Catégorie :</span>
                <span className="color5287c0"> {item.category}</span>
              </div>

              <div>
                <div className="color000000 fontMontserrat font-size-1_2">
                  <span>Descriptif technique</span>
                </div>
                <hr />
                {item.techDescription.map((description, index) => {
                  return (
                    <div key={index} className="techdescription">
                      <div
                        style={{
                          maxWidth: "100%",
                          overflowY: "auto",
                          padding: "0.5em",
                          border: "1px solid #f0f0f0",
                        }}
                        className="w-50 color9e9e9e fontLato fw-bolder font-size-0_9 bgcolorF0F8FF"
                      >
                        <span>{description.titre}</span>
                      </div>
                      <div
                        style={{
                          maxWidth: "100%",
                          overflowY: "auto",
                          padding: "0.5em",
                          border: "1px solid #f0f0f0",
                        }}
                        className="w-50 color000000 fontLato font-size-0_9"
                      >
                        <span>{description.valeur}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className=" btn-shop-container">
                <div>
                  {ctx ? (
                    <Button
                      className="btn-shopping-cart"
                      onClick={() => orderNow(item._id)}
                    >
                      Achetez maintenant
                    </Button>
                  ) : (
                    <Button
                      className="btn-shopping-cart"
                      onClick={() =>
                        setMsg(
                          "Veuillez vous connecter pour procéder au paiement."
                        )
                      }
                    >
                      Achetez maintenant
                    </Button>
                  )}
                </div>
                <div className="text-danger">{msg}</div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="p-md-5 p-2">
        <div className="m-5">
          <hr />
        </div>
        <div className="d-flex header-details">
          <div className="m-2">
            <i className="fa-solid fa-circle-exclamation colorffffff font-size-1_6"></i>
          </div>
          <div className="text-details">
            <p className="colorffffff font-size-1_6">A ne pas manquer</p>
          </div>
        </div>
        <div className="main-details p-md-5 p-2">
          {loadingProducts ? (
            <div className="reco-product-wrapper">
              {itemProduct.map((itemP) => (
                <div key={itemP._id} className="reco-product">
                  <div
                    style={{
                      width: "100%",
                      height: "50%",
                      paddingInline: "0.5rem",
                      objectFit: "contain",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#F8F8F8",
                    }}
                  >
                    <img
                      style={{ objectFit: "contain", overflow: "hidden" }}
                      src={itemP.images[0]}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      margin: "auto",
                      width: "80%",
                      padding: "1rem",
                      height: "50%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <p className="color000000 font-size-1_2 fontLato text-center">
                        {truncateName(itemP.name)}
                      </p>
                    </div>
                    <div>
                      <p className="color1F8ADD font-size-1_2 fontMontserrat text-center">
                        {formatCurrency(itemP.price)}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "0.5em",
                      }}
                    >
                      <Button size="sm" onClick={() => goToProduct(itemP._id)}>
                        Voir produit
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => increaseCartQuantity(itemP._id)}
                      >
                        <i className="fa-solid fa-cart-plus"> </i> Ajouter au
                        panier
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="d-flex justify-content-center vh-25 align-items-center">
              <ThreeDots color="#00BFFF" height={80} width={80} />
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
