import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deliveryTrad } from "../../services/formatString";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ThreeDots } from "react-loader-spinner";
import "../../styles/all.css";
import "../../styles/profil.css";
import axios from "axios";
import ProgressBar from "../../services/progressBar";
import useDocumentTitle from "../../services/documentTitle";

const OrderTrack = () => {
  useDocumentTitle("Suivi de commande");

  const [order, setOrder] = useState();
  const [orderLoading, setOrderLoading] = useState(false);

  const paramsId = useParams();

  useEffect(() => {
    const token = localStorage.getItem("jwt");

    async function getAllOrder() {
      await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/order/${paramsId.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setOrderLoading(true);
          setOrder(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    getAllOrder();
  }, []);

  return (
    <div className="bgcolorffffff" style={{ height: "100vh" }}>
      <div className=" w-100 h-100 d-flex justify-content-center align-items-center">
        {orderLoading ? (
          <div className="p-xl-4">
            <Container className="orderTrackContainer ">
              <Row className="p-3">
                <h1>{deliveryTrad(order.delivery_status)}</h1>
              </Row>
              <Row className="mx-md-5 p-3">
                <Col>
                  <Row>
                    <Col style={{ translate: "-30px" }} className="d-flex">
                      <div className="font-size-2">
                        {order.delivery_status === "pending" && (
                          <i
                            className="fa-solid fa-square-xmark"
                            style={{ color: "red" }}
                          ></i>
                        )}
                        {order.delivery_status === "send" && (
                          <i
                            className="fa-solid fa-square-xmark"
                            style={{
                              color: "#dcec00",
                            }}
                          ></i>
                        )}
                        {order.delivery_status === "delivered" && (
                          <i
                            className="fa-solid fa-square-check"
                            style={{ color: "green" }}
                          ></i>
                        )}
                      </div>
                      <span className="p-3">
                        {" "}
                        {deliveryTrad(order.delivery_status)}{" "}
                      </span>
                    </Col>
                    <div>
                      <span className="fontRedHatDisplay color9e9e9e">
                        LIVRAISON À
                      </span>
                    </div>
                    <div>
                      <span>{order.addressShipping.city}</span>
                    </div>
                    <div>
                      <span>{order.addressShipping.line1}</span>
                    </div>
                    <div>
                      <span>{order.addressShipping.line2}</span>
                    </div>
                    <div>
                      <span>{order.addressShipping.postal_code}</span>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Container className="pt-5 progressBarContainer">
              <ProgressBar status={order.delivery_status} />
            </Container>
          </div>
        ) : (
          <div className="d-flex justify-content-center vh-25 align-items-center">
            <ThreeDots color="#00BFFF" height={80} width={80} />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderTrack;
