import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import "../../styles/all.css";
import "../../styles/productCart.css";
import axios from "axios";
import { AuthContext } from "../../services/authProvider.jsx";

export function OrderButton({ cartItems }) {
  const [msg, setMsg] = useState();
  const ctx = useContext(AuthContext);

  const submitPayment = async () => {
    const token = localStorage.getItem("jwt");
    if (cartItems.length === 0) {
      setMsg("Votre panier est vide !");
    }
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/stuff/stripe`,
      data: {
        cartItems: cartItems,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) return (window.location = res.data.url);
        return res.json().then((json) => Promise.reject(json));
      })
      .catch((e) => {
        console.error(e.error);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        {ctx ? (
          <Button className="btn-shopping-cart" onClick={submitPayment}>
            Passer commande
          </Button>
        ) : (
          <Button
            className="btn-shopping-cart"
            onClick={() =>
              setMsg("Veuillez vous connecter pour procéder au paiement.")
            }
          >
            Passer commande
          </Button>
        )}
      </div>
      <div className="text-danger">{msg}</div>
    </div>
  );
}
