import React from "react";
import "../styles/all.css";
import "../styles/dressage.css";
import "../styles/contact.css";
import LogoCart1 from "../images/dressage/owner-psds8pwgput18jai4navghtr7ttwfq1tfxvhjdp24g.png";
import LogoCart2 from "../images/dressage/certificatepsds8oymj0rqwxbva4w8w02amfyj80y33t803qgao.png";
import LogoCart3 from "../images/dressage/chat-psdy0aldyqyl4hs01dex3449t2py9g3vzc9mt5x81s.png";
import LogoCart4 from "../images/dressage/lifebuoy-pscxos5h872p7n0fzoyg6k6i8pa62kdhvr7lfql1c0.png";
import MailForm from "../services/mailForm";
import useDocumentTitle from "../services/documentTitle";
import dressageIcon1 from "../images/dressage/dressage-icon-1.png";
import dressageIcon2 from "../images/dressage/dressage-icon-2.png";
import dressageIcon3 from "../images/dressage/dressage-icon-3.png";
import dressageIcon4 from "../images/dressage/dressage-icon-4.png";

const Dressage = () => {
  useDocumentTitle("Dressage");

  const page = "light";

  return (
    <div style={{ paddingBlock: "7vh" }}>
      <header className="header-dressage">
        <div className="text-header-dressage">
          <h1 className="text-center colorfa9b01df fontRedHat display-4 m-5">
            Nos cours de dressage
          </h1>
          <p className="text-center colorffffff fontLato font-size-1_2 mx-md-5 mx-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh
            morbi cursus enim nulla non pharetra vitae odio. Enim ullamcorper
            risus tincidunt dui fusce porttitor integer. Non et nisi sit id
            semper. Amet varius morbi turpis sed. Sed nulla augue at arcu vitae.
            Elementum quis interdum eu habitasse et. Nulla quis at nibh hac
            venenatis nisi eget.
          </p>
        </div>

        <div className="custom-shape-divider-bottom-1666267844">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </header>

      <section className="section-dressage-1">
        <div className="section-dressage-wrapper-1 p-lg-5 p-2">
          <div className="dressage-square-wrapper">
            <div className="dressage-square"></div>
            <div className="dressage-square-img"></div>
          </div>
          <div className="dressage-section-1-details">
            <div>
              <span className="fontComingSoon font-size-1_3 colorfa9b01df">
                Commence l'entraînement
              </span>
            </div>
            <div>
              <h3 className="fontBowlbyOneSC">
                Un dressage canin efficace, de vraies solutions comportementales
              </h3>
            </div>
            <div>
              <p className="fontOutfit font-size-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
            </div>
            <div className="dressage-section-1-ul p-2">
              <ul className="fontOutfit font-size-1">
                <li>
                  <i className="fa-solid fa-circle-check"></i>
                  <span>Développer une relation claire et aimante</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check"></i>
                  <span>Pouvoir communiquer avec eux</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check"></i>
                  <span>Soyez toujours désireux de plaire</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check"></i>
                  <span>Créer une relation en or</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="section-dressage-wrapper-2">
          <div className="dressage-text-div">
            <div className="color1F8ADD fontRedHat">
              <span>Pourquoi nous choisir</span>
            </div>
            <div className="fontRedHat">
              <h3>Nous sommes les experts de vos animaux de compagnie.</h3>
            </div>
            <div className="fontMerriweather">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
            </div>
          </div>
          <div className="dressage-card-grid">
            <div className="dressage-cart d-flex flex-column align-items-center text-center gap-2">
              <img src={LogoCart1} alt="" />
              <h5 className="font-size-1_3 ">Formateur professionnel</h5>
              <p className="font-size-1">
                Etiam tristique erat curabitur fames vitae consequat nostra.
              </p>
            </div>
            <div className="dressage-cart d-flex flex-column align-items-center text-center gap-2">
              <img src={LogoCart2} alt="" />
              <h5 className="font-size-1_3 ">Expert certifié</h5>
              <p className="font-size-1">
                Etiam tristique erat curabitur fames vitae consequat nostra.
              </p>
            </div>
            <div className="dressage-cart d-flex flex-column align-items-center text-center gap-2">
              <img src={LogoCart3} alt="" />
              <h5 className="font-size-1_3 ">Consultations gratuites</h5>
              <p className="font-size-1">
                Etiam tristique erat curabitur fames vitae consequat nostra.
              </p>
            </div>
            <div className="dressage-cart d-flex flex-column align-items-center text-center gap-2">
              <img src={LogoCart4} alt="" />
              <h5 className="font-size-1_3 ">
                Assistance Premium 24h/24 et 7j/7
              </h5>
              <p className="font-size-1">
                Etiam tristique erat curabitur fames vitae consequat nostra.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="dressage-section-about  p-xl-5 p-4">
        <div className="text-center">
          <h1 className="fontComingSoon">Nos séances...</h1>
        </div>
        <div className="">
          <h5 className="">
            Chaque chien possède un comportement différent, Lorem ipsum dolor
            sit amet, consectetur adipiscing elit.
          </h5>
        </div>
        <div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
            tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.Sed urna
            lacus, egestas a pellentesque vel, ornare at est. Vestibulum elit
            eros, mattis ac ultricies consequat, malesuada eget nisi.
          </p>
        </div>
        <div className="dressage-about-div-wrapper d-block d-xl-flex gap-3 m-auto p-xl-5 p-3 text-center">
          <div className="d-flex flex-column gap-3">
            <div id="dressage-id-1" className="dressage-about-img"></div>
            <div className="dressage-icon-div">
              <img src={dressageIcon2} alt="" />
            </div>
            <div>
              <h5 className="font-size-1_3 fontRedHat">Cours collectifs</h5>
            </div>
            <div>
              <p className="font-size-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
            </div>
          </div>
          <div className="d-flex flex-column gap-3">
            <div id="dressage-id-2" className="dressage-about-img"></div>
            <div className="dressage-icon-div">
              <img src={dressageIcon4} alt="" />
            </div>
            <div>
              <h5 className="font-size-1_3 fontRedHat">Cours individuels</h5>
            </div>
            <div>
              <p className="font-size-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
            </div>
          </div>
          <div className="d-flex flex-column gap-3">
            <div id="dressage-id-3" className="dressage-about-img"></div>
            <div className="dressage-icon-div">
              <img src={dressageIcon1} alt="" />
            </div>
            <div>
              <h5 className="font-size-1_3 fontRedHat">Education</h5>
            </div>
            <div>
              <p className="font-size-1 ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
            </div>
          </div>
          <div className="d-flex flex-column gap-3">
            <div id="dressage-id-4" className="dressage-about-img"></div>
            <div className="dressage-icon-div">
              <img src={dressageIcon3} alt="" />
            </div>
            <div>
              <h5 className="font-size-1_3 fontRedHat">Rééducation</h5>
            </div>
            <div>
              <p className="font-size-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="dressage-section-tarif p-2 p-md-5">
        <div className="p-xl-5 p-3">
          <div className="text-center p-4">
            <h1 className="fontComfortaa colorffffff">Nos tarifs...</h1>
          </div>
          <div className=" d-flex justify-content-evenly align-items-center flex-column flex-md-row gap-3">
            <div className="dressage-card">
              <div>
                <h5 className="fontRedHat">Ut elit tellus</h5>
              </div>
              <div>
                <span className="fontRedHat font-size-1_3 colord30019">
                  75 €
                </span>
              </div>
              <div>
                <p className="fontLato font-size-1_1">
                  Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                  dapibus leo.
                </p>
              </div>
            </div>
            <div className="dressage-card">
              <div>
                <h5 className="fontRedHat">Ut elit tellus</h5>
              </div>
              <div>
                <span className="fontRedHat font-size-1_3 colord30019">
                  75 €
                </span>
              </div>
              <div>
                <p className="fontLato font-size-1_1">
                  Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                  dapibus leo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="dressage-section-wrapper p-lg-5 p-2">
        <div className="text-center">
          <h1 className="fontComingSoon">Contactez "Nom de l'entreprise..."</h1>
        </div>
        <div className="dressage-section-contact">
          <div className="p-2 p-lg-5">
            <h4>Prendre rendez-vous par Mail</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam a
              sagittis sem. Curabitur luctus, ante eget dignissim aliquam, enim
              odio hendrerit lorem, eu maximus nibh felis vel erat.{" "}
            </p>
            <MailForm page={page} />
          </div>
          <div className="p-2 p-lg-5">
            <h4>Prendre rendez-vous par téléphone</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam a
              sagittis sem. Curabitur luctus, ante eget dignissim aliquam, enim
              odio hendrerit lorem, eu maximus nibh felis vel erat.{" "}
            </p>
            <div>
              <span className="fontRubik">
                <strong>Tel :</strong> 06 00 00 00 00
              </span>
            </div>
            <div className="dressage-div-wrapper p-xl-5 p-3">
              <div className="dressage-div ">
                <div className="w-80 p-2 p-xl-3">
                  <div>
                    <i
                      style={{ color: "#202020" }}
                      className="fa-solid fa-phone font-size-1_7 text-start"
                    ></i>
                  </div>

                  <div>
                    <a
                      href="tel:0600000000"
                      className="font-size-1_2 fontMontserrat dressage-tel"
                    >
                      Appelez-nous au 06 00 00 00 00
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dressage;
