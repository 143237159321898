import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Corgi from "../images/corgi_404.svg";
import "../styles/all.css";
import useDocumentTitle from "../services/documentTitle";

const Page404 = () => {
  useDocumentTitle("Page 404");
  const navigate = useNavigate();

  return (
    <div className="vh-100 d-flex flex-column align-items-center justify-content-between pb-5">
      <div>
        <h1 className="text-center p-5 m-5 font-size-5 fontMontserrat color1F8ADD">
          ERREUR 404
        </h1>
      </div>
      <div className="w-25">
        <img className="w-100" src={Corgi} alt="" />
      </div>
      <div>
        <h3 className="text-center font-size-3_2 color1F8ADD">
          Cette page n'existe pas
        </h3>
      </div>
      <div>
        <Button onClick={() => navigate("/")}>Retourner à l'accueil</Button>
      </div>
    </div>
  );
};

export default Page404;
