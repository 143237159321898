import React from "react";
import "../../styles/all.css";
import "../../styles/productCart.css";
import useDocumentTitle from "../../services/documentTitle";

export default function SuccessPayment() {
  useDocumentTitle("Success payment");

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#EBF0F5",
        textAlign: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
        }}
        className="success-div-wrapper"
      >
        <div
          className="success-div-round"
          style={{
            backgroundColor: "#F8FAF5",
          }}
        >
          <i
            className="success-check-i"
            style={{
              color: "#9ABC66",
            }}
          >
            ✓
          </i>
        </div>
        <h2
          className=" fontBowlbyOneSC"
          style={{ color: "#88B04B", marginBlock: "15px" }}
        >
          Succès !
        </h2>
        <p className="fontRedHat font-size-1_1" style={{ color: "#404F5E" }}>
          Nous avons reçu votre demande d'achat;
          <br /> Un mail vous sera envoyé avec le récapitulatif de commande
          ainsi qu'une facture !
        </p>
      </div>
    </div>
  );
}
