import React, { useState, useEffect } from "react";
import "../styles/all.css";
import "../styles/boutique.css";
import Form from "react-bootstrap/Form";
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Products from "../components/boutique/products";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import useDocumentTitle from "../services/documentTitle";

const Boutique = () => {
  const [loading, setLoading] = useState(false);
  const [json, setJson] = useState();

  useDocumentTitle("Boutique");

  useEffect(() => {
    const getProducts = async () => {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/stuff/`);
      setJson(data);
      setLoading(true);
    };
    getProducts();
  }, []);

  const [radioValue, setRadioValue] = useState("4");

  const radiosCategory = [
    { name: "Accessoires", value: "1" },
    { name: "Jouets", value: "2" },
    { name: "Nourritures", value: "3" },
    { name: "Tout les produits", value: "4" },
  ];

  const [sortType, setSortType] = useState("0");

  const sortByValue = [
    { name: "Trie par défault", value: "0" },
    { name: "Prix : par ordre croissant", value: "1" },
    { name: "Prix : par ordre décroissant", value: "2" },
    { name: "Dernières nouveautés", value: "3" },
  ];

  const [searchInput, setSearchInput] = useState("");

  return (
    <div className="page-boutique-bg">
      <header className="header-boutique">
        <div className="background-opacity"></div>
        <div className="header-text"></div>
        <div className="header-img-text-wrapper">
          <div className="img-shop-wrapper">
            <div className="img-shop"></div>
          </div>
          <div className="text-wrapper-div">
            <div className="text-wrapper">
              <h1 className="colorffffff fontMontserrat font-size-2_4 text-center p-5">
                Bienvenue sur la boutique !
              </h1>
              <p className="colorffffff fontLato font-size-1_3 text-center">
                Do mollit ut fugiat sint dolore. Labore mollit consequat eu
                nisi. Ut laborum cupidatat deserunt mollit nulla ad velit sit
                enim velit est in. Voluptate ipsum non ullamco incididunt do.
              </p>
            </div>
          </div>
        </div>
      </header>
      <section className="sectionFiltre">
        <ButtonGroup className="filtre-shop-img ">
          {radiosCategory.map((radio, idx) => (
            <div key={idx}>
              <ToggleButton
                className="toggleButton"
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.target.value)}
              >
                {radio.name}
              </ToggleButton>
            </div>
          ))}
        </ButtonGroup>
        <DropdownButton
          title="Catégories des produits"
          className="DropdownButtonWrapper"
        >
          {radiosCategory.map((radio, idx) => (
            <Dropdown.Item
              className="toggleButtonDropdown"
              key={idx}
              id={radio.value}
              onClick={(e) => setRadioValue(e.target.id)}
            >
              {radio.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </section>
      <section
        className=" d-md-flex align-items-center justify-content-between"
        style={{ padding: "5% 15% 5% 15%" }}
      >
        <div className="search-bar-container">
          <div className="d-flex">
            <Form.Control
              onChange={(e) => setSearchInput(e.target.value)}
              className="form-control-search"
              placeholder="Search..."
              type="text"
              id="inputFilter"
              aria-describedby="textFilter"
            />
          </div>
        </div>
        <div className="search-filtre-wrapper">
          <div>
            <Form.Select
              className="search-filtre-select"
              onChange={(e) => setSortType(e.target.value)}
              aria-label="Default select example"
            >
              {sortByValue.map((sortBy, idx) => (
                <option key={idx} value={sortBy.value}>
                  {sortBy.name}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </section>
      {loading === true ? (
        <div>
          <Products
            json={json}
            radioValue={radioValue}
            sortType={sortType}
            searchInput={searchInput}
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center vh-25 align-items-center">
          <ThreeDots color="#00BFFF" height={80} width={80} />
        </div>
      )}
    </div>
  );
};

export default Boutique;
