import React from "react";
import { useShoppingCart } from "../../services/shoppingCartContext.jsx";
import { useNavigate } from "react-router-dom";
import Stack from "react-bootstrap/Stack";
import Col from "react-bootstrap/Col";
import "../../styles/all.css";
import "../../styles/productCart.css";
import Button from "react-bootstrap/esm/Button";
import { formatCurrency, formatNumber } from "../../services/formatCurrency";
import { truncateName } from "../../services/formatString";

export function CartItem({ id, quantity, storeItems }) {
  const navigateToProduct = useNavigate();

  const goToProduct = (id) => {
    navigateToProduct(`/store/product/${id}`);
  };

  const { removeFromCart } = useShoppingCart();

  const item = storeItems.find((i) => i._id === id);
  if (item == null) return null;

  const price = formatNumber(item.price);
  const itemTotalPrice = price * quantity;
  return (
    <Stack
      direction="horizontal"
      gap={2}
      className="stack-shop-product d-flex align-items-center"
    >
      <Col>
        <img src={item.images[0]} alt="" />
      </Col>
      <Col className="me-auto">
        <div className="d-flex align-items-center gap-2">
          <span
            className=" fontRedHatDisplay font-size-0_9 color9e9e9e cart-product-span-name"
            onClick={() => goToProduct(item._id)}
          >
            {truncateName(item.name)}{" "}
          </span>
        </div>
        <div>
          {quantity > 1 && (
            <span style={{ fontSize: "12px" }} className="text-muted ">
              x{quantity}
            </span>
          )}
        </div>
        <div className="color737373 fontRedHatDisplay">
          <span style={{ fontSize: "13px" }}>{formatCurrency(item.price)}</span>
        </div>
      </Col>
      <Col className="fontRedHatDisplay color9e9e9e text-center">
        <span style={{ fontSize: "15px" }}>
          {formatCurrency(itemTotalPrice)}
        </span>
      </Col>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => removeFromCart(item._id)}
      >
        X
      </Button>
    </Stack>
  );
}
