import { React, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Corgi from "../images/corgi_404.svg";
import "../styles/all.css";
import "../styles/profil.css";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";
import InputGroup from "react-bootstrap/InputGroup";
import { ThreeDots } from "react-loader-spinner";
import useDocumentTitle from "../services/documentTitle";

const passwordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "Mot de passe doit être plus grand que 8 caractères")
    .max(50, "Mot de passe doit être plus petit que 50 caractères")
    .required("Ce champ est obligatoire"),
  newConfirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Le mot de passe doit être identifique!")
    .required("Ce champ est obligatoire"),
});

const ChangePassword = () => {
  useDocumentTitle("Changement mot de passe");

  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const [msg, setMsg] = useState({ type: "", message: "" });

  const [userInfo, setUserInfo] = useState({});

  const [validUrl, setValidUrl] = useState(true);
  const [loading, setLoading] = useState(false);

  const param = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmailUrl = async () => {
      const url = `${process.env.REACT_APP_API_URL}/auth/users/${param.id}/reset/verify/${param.token}`;
      await axios
        .get(url)
        .then((response) => {
          setUserInfo(response.data);
          setLoading(true);
          setValidUrl(true);
        })
        .catch((error) => {
          console.log(error);
          setLoading(true);
          setValidUrl(false);
        });
    };

    verifyEmailUrl();
  }, []);

  const postPassword = (values, { setSubmitting, resetForm }) => {
    axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/auth/change/password`,
      data: {
        id: userInfo.id,
        newPassword: values.newPassword,
      },
    })
      .then((response) => {
        setSubmitting(false);
        resetForm();
        setMsg({ type: "success", message: response.data.message });
      })
      .catch((error) => {
        setSubmitting(false);
        resetForm();
        console.error(error);
      });
  };

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      {validUrl ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
          }}
        >
          <div className="container text-center">
            <Container className="p-5">
              <Row className="text-start">
                <h3 className="">
                  <span className="fontRedHat display-6">Bonjour</span>{" "}
                  <span className="fontLato fst-italic">
                    {userInfo.firstName} {userInfo.lastName}
                  </span>
                </h3>
              </Row>
              <Row className="text-start font-size-1 fontLato pt-3">
                <p>Ci-dessous, veuillez rentrer votre nouveau mot de passe.</p>
              </Row>
            </Container>
            {loading ? (
              <Formik
                validationSchema={passwordSchema}
                onSubmit={postPassword}
                initialValues={{
                  newPassword: "",
                  newConfirmPassword: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Container>
                      <Row>
                        <Col sm>
                          <span className="fw-bolder">
                            Nouveau mot de passe
                          </span>
                        </Col>
                        <Col sm>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                placeholder="Nouveau mot de passe"
                                type={passwordType1}
                                name="newPassword"
                                value={values.newPassword}
                                onChange={handleChange}
                                isValid={
                                  touched.newPassword && !errors.newPassword
                                }
                                isInvalid={!!errors.newPassword}
                                onBlur={handleBlur}
                                required
                              />
                              <InputGroup.Text
                                onClick={() =>
                                  passwordType1 === "password"
                                    ? setPasswordType1("text")
                                    : setPasswordType1("password")
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {passwordType1 === "password" ? (
                                  <i className="fa-regular fa-eye-slash"></i>
                                ) : (
                                  <i className="fa-regular fa-eye"></i>
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component="span"
                              name="newPassword"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                    <hr />
                    <Container>
                      <Row>
                        <Col sm>
                          <span className="fw-bolder">
                            Confirmer nouveau mot de passe
                          </span>
                        </Col>
                        <Col sm>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                placeholder="Confirmer nouveau mot de passe"
                                type={passwordType2}
                                name="newConfirmPassword"
                                value={values.newConfirmPassword}
                                onChange={handleChange}
                                isValid={
                                  touched.newConfirmPassword &&
                                  !errors.newConfirmPassword
                                }
                                isInvalid={!!errors.newConfirmPassword}
                                onBlur={handleBlur}
                                required
                              />
                              <InputGroup.Text
                                onClick={() =>
                                  passwordType2 === "password"
                                    ? setPasswordType2("text")
                                    : setPasswordType2("password")
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {passwordType2 === "password" ? (
                                  <i className="fa-regular fa-eye-slash"></i>
                                ) : (
                                  <i className="fa-regular fa-eye"></i>
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component="span"
                              name="newConfirmPassword"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                    <hr />
                    <div className="d-flex justify-content-center p-3">
                      <Button variant="primary" type="submit">
                        Envoyer
                      </Button>
                    </div>
                    <Alert
                      variant={
                        msg.type === "success"
                          ? "success"
                          : msg.type === "error"
                          ? "danger"
                          : null
                      }
                      className="text-center"
                    >
                      <span>{msg.message}</span>
                    </Alert>
                  </Form>
                )}
              </Formik>
            ) : (
              <div className="d-flex justify-content-center vh-25 align-items-center">
                <ThreeDots color="#00BFFF" height={80} width={80} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-between pb-5">
          <div>
            <h1 className="text-center p-5 m-5 font-size-5 fontMontserrat color1F8ADD">
              ERREUR 404
            </h1>
          </div>
          <div className="w-25">
            <img className="w-100" src={Corgi} alt="" />
          </div>
          <div>
            <h3 className="text-center font-size-3_2 color1F8ADD">
              Cette page n'existe pas
            </h3>
          </div>
          <div>
            <Button onClick={() => navigate("/")}>Retourner à l'accueil</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
