import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/all.css";
import "../../styles/allDog.css";
import Pagination from "../pagination";
import { formatCurrency } from "../../services/formatCurrency";
import { truncateString } from "../../services/formatString";
import { getAnniversary } from "../../services/formatString";

const GetAllDog = ({
  json,
  sortType,
  searchInput,
  sortRace,
  minAge,
  maxAge,
  sortGender,
}) => {
  const myRef = useRef();

  const [chiens, setChiens] = useState([]);

  const navigateToProduct = useNavigate();

  const goToProduct = (id) => {
    navigateToProduct(`/dogs/${id}`);
  };

  const sortByRace = () => {
    if (sortRace === "y") {
      return json;
    } else {
      return json.filter((r) => r.race === sortRace);
    }
  };

  const sortByAge = (result) => {
    const today = new Date();

    var chien = [...result].filter(function(o) {
      var birthDate = new Date(o.anniversary);
      var year = today.getFullYear() - birthDate.getFullYear();
      return year > minAge && year < maxAge;
    });

    return chien;
  };

  const sortBySexe = (result1) => {
    if (sortGender === "0") {
      return result1;
    }
    if (sortGender === "1") {
      return [...result1].filter((g) => g.gender === "Mâle");
    }
    if (sortGender === "2") {
      return [...result1].filter((g) => g.gender === "Femelle");
    } else return result1;
  };

  const sortByFc = (result2) => {
    if (sortType === "0") {
      return result2;
    }
    if (sortType === "1") {
      return [...result2].sort((a, b) => a.price - b.price);
    }
    if (sortType === "2") {
      return [...result2].sort((a, b) => b.price - a.price);
    }
    if (sortType === "3") {
      return [...result2].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else return result2;
  };

  const searchFunction = (result3) => {
    if (searchInput !== "") {
      return result3.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
    } else {
      return result3;
    }
  };

  useEffect(
    (json) => {
      const result = sortByRace(json);
      const result1 = sortByAge(result);
      const result2 = sortBySexe(result1);
      const result3 = sortByFc(result2);
      const result4 = searchFunction(result3);
      setChiens(result4);
    },
    [sortRace, minAge, maxAge, sortGender, sortType, searchInput]
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage] = useState(12);

  const indexOfLastProduct = currentPage * productPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productPerPage;

  const currentProduct = chiens.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="colorffffff" ref={myRef}>
      {currentProduct.length > 0 ? (
        <section className="chiens-section-wrapper">
          <div className="justify-content-md-center currentChienRow">
            {currentProduct.map((chien) => (
              <div
                key={chien._id}
                className="d-flex flex-column align-items-center chiens-col "
              >
                <div
                  className="imgDivWrapperChiens"
                  style={{
                    backgroundImage: `url(${chien.images[0]})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  onClick={() => goToProduct(chien._id)}
                ></div>
                <div className="textDivWrapperChiens">
                  <div className="">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="color000000  font-size-1_1 fontMontserrat">
                        {truncateString(chien.name)}
                      </span>
                      <span className="color1F8ADD font-size-0_8 fontMontserrat text-center">
                        {formatCurrency(chien.price)}
                      </span>
                    </div>
                    <div>
                      <span className="color000000  font-size-0_9 fontLato">
                        {chien.race}
                      </span>
                    </div>
                    <hr />
                    <div className="d-flex flex-column align-items-center">
                      <div>
                        <i
                          style={{ color: "#007DDD" }}
                          className="fa-solid fa-cake-candles px-2"
                        ></i>
                        <span className="color9e9e9e font-size-0_8 fontMontserrat">
                          Age :
                        </span>
                        <span className="color000000 font-size-0_8 fontRubik">
                          {" "}
                          {getAnniversary(chien.anniversary)}
                        </span>
                      </div>
                      <div>
                        <i
                          style={{ color: "#007DDD" }}
                          className="fa-solid fa-venus-mars px-2"
                        ></i>
                        <span className="color9e9e9e font-size-0_8 fontMontserrat">
                          Sexe :
                        </span>
                        <span className="color000000 font-size-0_8 fontRubik">
                          {" "}
                          {chien.gender}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center paginationDiv">
            <Pagination
              productPerPage={productPerPage}
              totalProducts={chiens.length}
              paginate={paginate}
              currentPage={currentPage}
              myRef={myRef}
            />
          </div>
        </section>
      ) : (
        <div>
          <div className="vh-30 d-flex justify-content-center align-items-center">
            <div className="colorffffff">
              <h1>Aucun chiens ne peut être affiché</h1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetAllDog;
