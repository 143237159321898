import React from "react";
import "../styles/all.css";
import "../styles/boutique.css";

const Pagination = ({
  productPerPage,
  totalProducts,
  paginate,
  currentPage,
  myRef,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalProducts / productPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination fontLato">
        <li className="font-size-1">
          {currentPage === 1 ? (
            <div
              className="page-link"
              style={{
                backgroundColor: "#9C9C9C",
                borderColor: "#9C9C9C",
                color: "#272727",
                pointerEvents: "none",
              }}
            >
              <i className="fa-sharp fa-solid fa-chevron-left "></i>
            </div>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              className="page-link"
              onClick={() => {
                paginate(currentPage - 1);
                myRef.current.scrollIntoView();
              }}
            >
              <i className="fa-sharp fa-solid fa-chevron-left"></i>
            </div>
          )}
        </li>
        {pageNumbers.map((number) => (
          <div key={number} className="font-size-1">
            {currentPage === number ? (
              <li>
                <div
                  style={{
                    backgroundColor: "#9C9C9C",
                    borderColor: "#9C9C9C",
                    color: "#272727",
                    pointerEvents: "none",
                  }}
                  className="page-link"
                >
                  {number}
                </div>
              </li>
            ) : (
              <li>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    paginate(number);
                    myRef.current.scrollIntoView();
                  }}
                  className="page-link"
                >
                  {number}
                </div>
              </li>
            )}
          </div>
        ))}

        <li className="font-size-1">
          {currentPage === pageNumbers.length ? (
            <div
              className="page-link"
              style={{
                backgroundColor: "#9C9C9C",
                borderColor: "#9C9C9C",
                color: "#272727",
                pointerEvents: "none",
              }}
            >
              <i className="fa-sharp fa-solid fa-chevron-right "></i>
            </div>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              className="page-link"
              onClick={() => {
                paginate(currentPage + 1);
                myRef.current.scrollIntoView();
              }}
            >
              <i className="fa-sharp fa-solid fa-chevron-right "></i>
            </div>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
