import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

export const AuthContext = createContext({});

const AuthProvider = (props) => {
  const [user, setUser] = useState();
  const token = localStorage.getItem("jwt");
  useEffect(() => {
    if (token == null) {
      return;
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/auth/user`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setUser(response.data);
        });
    }
  }, []);

  return (
    <AuthContext.Provider value={user}>{props.children}</AuthContext.Provider>
  );
};

export default AuthProvider;
