import React, { useMemo } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { ThreeDots } from "react-loader-spinner";
import mapStyles from "../models/mapStyles";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../styles/all.css";
import "../styles/contact.css";
import MailForm from "../services/mailForm";
import useDocumentTitle from "../services/documentTitle";

const Contact = () => {
  useDocumentTitle("Contact");

  const page = "dark";

  const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };

  const containerStyle = {
    width: "100%",
    height: "80vh",
  };

  const center = useMemo(() => ({
    lat: 48.85840013189176,
    lng: 2.2944800605957805,
  }));

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const planning = [
    { day: "Lundi", hours: "8h - 21h" },
    { day: "Mardi", hours: "8h - 21h" },
    { day: "Mercredi", hours: "8h - 21h" },
    { day: "Jeudi", hours: "8h - 21h" },
    { day: "Vendredi", hours: "8h - 21h" },
    { day: "Samedi", hours: "8h - 21h" },
    { day: "Dimanche", hours: "Fermé" },
  ];

  return (
    <div>
      {isLoaded ? (
        <div>
          <header className="headerDiv">
            <div className="colorffffff text-center">
              <h1 className="fontMontserrat display-4">Nous contacter</h1>
              <span className="font-size-0_9">Lorem ipsum dolor sit amet</span>
            </div>
          </header>
          <section className="contact-section">
            <Container className="p-xl-5 p-3">
              <Row>
                <Col className="colorffffff">
                  <h3 className="fontMerriweather">Entrer en contact</h3>
                  <p className="font-size-1 w-90">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus pellentesque nec lacus sit amet finibus. Nulla non
                    sollicitudin orci. Aliquam consequat metus in blandit
                    faucibus.
                  </p>
                  <div className="d-flex flex-column align-items-center p-lg-5 p-2 gap-3">
                    <div className="d-flex contact-div-wrapper">
                      <div className="font-size-1_6 color98caff">
                        <i className="fa-solid fa-phone-flip"></i>
                      </div>
                      <div className="d-flex flex-column px-3">
                        <div>
                          <span className="font-size-1_3 fontRubik">
                            Notre Numéro de Télephone
                          </span>
                        </div>
                        <a
                          href="tel:0600000000"
                          className="font-size-1_1 contact-tel"
                        >
                          06 00 00 00 00
                        </a>
                      </div>
                    </div>
                    <div className="d-flex contact-div-wrapper">
                      <div className="d-flex align-items-center font-size-1_6 color98caff">
                        <i className="fa-solid fa-house"></i>
                      </div>
                      <div className="d-flex flex-column  px-3">
                        <div>
                          <span className="font-size-1_3 fontRubik">
                            Notre adresse
                          </span>
                        </div>
                        <span className="font-size-1">720 Rue des .....</span>
                        <span className="font-size-1">42424 Paris</span>
                      </div>
                    </div>

                    <div className="d-flex contact-div-wrapper">
                      <div className="d-flex align-items-center font-size-1_6 color98caff">
                        <i className="fa-solid fa-envelope"></i>
                      </div>

                      <div className="d-flex flex-column px-3">
                        <div>
                          <span className="font-size-1_3 fontRubik">
                            Adresse Email
                          </span>
                        </div>

                        <span className="font-size-1">bonjour@hotmail.com</span>
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2 contact-reseaux">
                      <div className="font-size-1_3 fontRubik">
                        <span>nos réseaux</span>
                      </div>
                      <div className="d-flex gap-5 font-size-1_5 color98caff">
                        <div>
                          <a href="#">
                            <i className="fa-brands fa-facebook"></i>
                          </a>
                        </div>
                        <div>
                          <a href="#">
                            <i className="fa-brands fa-instagram"></i>
                          </a>
                        </div>
                        <div>
                          <a href="#">
                            <i className="fa-brands fa-youtube"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md>
                  <div className="m-2">
                    <div className="d-flex justify-content-center align-items-center ph-50 ">
                      <h3 className="colorffffff fontMerriweather">
                        Pour nous écrire, c’est simple !
                        <span role="img" aria-label="logo">
                          {" "}
                          🖊
                        </span>
                      </h3>
                    </div>
                  </div>
                  <MailForm page={page} />
                </Col>
              </Row>
            </Container>
          </section>
          <section className="contact-section-planning p-2 p-lg-5 pb-5">
            <Container className="contact-planning p-2 p-lg-5 ">
              <Col className="d-flex flex-column gap-2">
                <div>
                  <img src="" alt="" />
                </div>
                <div className="">
                  <h3>Horaires d'ouvertures</h3>
                </div>
                <div className="">
                  <p>
                    Donec feugiat lectus mi, nec scelerisque massa viverraac.
                    Vivam vestibulum ornare imperdiet aecenasma ximus fringilla.
                  </p>
                </div>
                <Container>
                  {planning.map((el, index) => {
                    return (
                      <div key={index}>
                        <div className="planning-container">
                          <div>
                            <span className="planning-day font-size-1_2">
                              {el.day}
                            </span>
                          </div>
                          <div>
                            <span className="planning-hours font-size-1 ">
                              {el.hours}
                            </span>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                </Container>
              </Col>
            </Container>
          </section>
          <section className="contact-section-map">
            <div className="contact-div-map">
              <h4 className="fontRedHat">Où nous trouver</h4>
              <p className="fontRubik m-auto">
                Sed nulla nulla, laoreet vitae purus at, aliquet finibus leo.
                Sed placerat leo a nisi scelerisque, at pellentesque sem
                interdum. Maecenas id libero sed leo porttitor venenatis.
              </p>
            </div>
          </section>
          <GoogleMap
            className="googleMap"
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            options={options}
          >
            <Marker position={center}></Marker>
          </GoogleMap>
        </div>
      ) : (
        <div className="d-flex justify-content-center vh-25 align-items-center">
          <ThreeDots color="#00BFFF" height={80} width={80} />
        </div>
      )}
    </div>
  );
};

export default Contact;
