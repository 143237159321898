import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import "../../styles/all.css";
import "../../styles/profil.css";
import Alert from "react-bootstrap/Alert";

const initialValuesInfo = {
  firstName: "",
  lastName: "",
  email: "",
};

const infoSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "trop petit!")
    .max(50, "trop long!")
    .required("Ce champ est obligatoire"),
  lastName: Yup.string()
    .min(2, "trop petit!")
    .max(50, "trop long!")
    .required("Ce champ est obligatoire"),
  email: Yup.string()
    .email("email invalide")
    .required("Ce champ est obligatoire"),
});

const MyInfoUpdate = ({ userInfo }) => {
  const token = localStorage.getItem("jwt");

  const [enabled, setEnabled] = useState("");

  const [msg, setMsg] = useState("");
  const [className, setClassName] = useState("");

  let date = new Date(userInfo.createdAt);

  useEffect(() => {
    const setInitialValues = () => {
      initialValuesInfo.firstName = userInfo.firstName;
      initialValuesInfo.lastName = userInfo.lastName;
      initialValuesInfo.email = userInfo.email;
    };
    setInitialValues();
  }, []);

  const updateInfo = (values, { setSubmitting, resetForm }) => {
    axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/auth/update/info`,
      data: values,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setSubmitting(false);
        resetForm();
        setMsg(response.data.message);
        setClassName("success");
      })
      .catch(() => {
        setSubmitting(false);
        resetForm();
      });
  };

  const enabledInput = (value) => {
    setEnabled(value);
  };

  return (
    <div>
      <Formik
        initialValues={initialValuesInfo}
        validationSchema={infoSchema}
        onSubmit={updateInfo}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col sm>
                  <span className="fw-bolder">Adresse e-mail</span>
                </Col>
                <Col sm>
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder={userInfo.email}
                      disabled={enabled === "email" ? false : true}
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isValid={touched.email && !errors.email}
                      isInvalid={!!errors.email}
                      onBlur={handleBlur}
                      required
                    />
                    <ErrorMessage
                      component="span"
                      name="email"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md>
                  <div className="d-flex flex-row-reverse justify-content-center">
                    <Button
                      id="email"
                      onClick={(e) => {
                        enabledInput(e.target.id);
                      }}
                    >
                      modifier
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
            <hr />
            <Container>
              <Row>
                <Col sm>
                  <span className="fw-bolder">Prénom</span>
                </Col>
                <Col sm>
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder={userInfo.firstName}
                      disabled={enabled === "firstName" ? false : true}
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isValid={touched.firstName && !errors.firstName}
                      isInvalid={!!errors.firstName}
                      onBlur={handleBlur}
                      required
                    />
                    <ErrorMessage
                      component="span"
                      name="firstName"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md>
                  <div className="d-flex flex-row-reverse justify-content-center">
                    <Button
                      id="firstName"
                      onClick={(e) => {
                        enabledInput(e.target.id);
                      }}
                    >
                      modifier
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
            <hr />
            <Container>
              <Row>
                <Col sm>
                  <span className="fw-bolder">Nom</span>
                </Col>
                <Col sm>
                  <Form.Group className="mb-3">
                    <Form.Control
                      disabled={enabled === "lastName" ? false : true}
                      placeholder={userInfo.lastName}
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isValid={touched.lastName && !errors.lastName}
                      isInvalid={!!errors.lastName}
                      onBlur={handleBlur}
                      required
                    />
                    <ErrorMessage
                      component="span"
                      name="lastName"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>

                <Col md>
                  <div className="d-flex flex-row-reverse justify-content-center">
                    <Button
                      id="lastName"
                      onClick={(e) => {
                        enabledInput(e.target.id);
                      }}
                    >
                      modifier
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
            <hr />
            <Row>
              <Alert variant={className}>{msg}</Alert>
            </Row>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                type="submit"
                disabled={enabled ? false : true}
              >
                Envoyer
              </Button>
            </div>
            <hr />
            <Container>
              <Row>
                <Col xs>
                  <span className="fw-bolder">Membre depuis le </span>
                </Col>
                <Col xs>
                  <span> {date.toLocaleDateString()}</span>
                </Col>
              </Row>
            </Container>
            <hr />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MyInfoUpdate;
