import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import { formatCurrency, formatNumber } from "../../services/formatCurrency";
import { truncateString, truncateName } from "../../services/formatString";
import "../../styles/all.css";
import "../../styles/profil.css";
import axios from "axios";
import useDocumentTitle from "../../services/documentTitle";

const Refund = () => {
  useDocumentTitle("Remboursement");

  const [order, setOrder] = useState();
  const [orderLoading, setOrderLoading] = useState(false);

  const [product, setProduct] = useState([]);
  const [itemLoading, setItemLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const paramsId = useParams();

  const token = localStorage.getItem("jwt");

  useEffect(() => {
    async function getOrder() {
      await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/order/${paramsId.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          let metadata = response.data.metadata;
          setOrderLoading(true);
          setOrder(response.data);
          axios
            .get(`${process.env.REACT_APP_API_URL}/stuff/`)
            .then((response) => {
              setProduct(response.data);
              setItemLoading(true);
              let item = metadata.map((item) => {
                return response.data.find((x) => x._id === item.id);
              });

              let dataObject = item.map((obj) => {
                return {
                  id: obj._id,
                  quantity: 1,
                  checked: false,
                  img: obj.images[0],
                  name: obj.name,
                  prix: obj.price,
                };
              });

              setFormData(dataObject);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
    getOrder();
  }, []);

  const [resData, setResData] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    if (resData.length === 0) return null;
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/order/refund`,
      data: {
        orderId: order._id,
        refundObj: resData,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setMsg("Renvoi de produits bien pris en compte.");
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [formData, setFormData] = useState([]);

  const navigateToProduct = useNavigate();

  const goToProduct = (itemId) => {
    navigateToProduct(`/store/product/${itemId}`);
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="bgcolorffffff">
      <div>
        <div className="vh-40 d-flex justify-content-center align-items-center">
          <div className="">
            <h1 className="text-center">Page de remboursement</h1>
          </div>
        </div>
        <div className=" w-100 h-100 d-flex justify-content-center align-items-center">
          {orderLoading ? (
            <div className="p-xl-4">
              <Container>
                <Form onSubmit={onSubmit}>
                  <Row>
                    {itemLoading ? (
                      <Col>
                        {!order.return ? (
                          <div>
                            {order.metadata.map((cart, index) => {
                              const item = product.find(
                                (i) => i._id === cart.id
                              );
                              if (item == null) return null;
                              const price = formatNumber(item.price);
                              const itemTotalPrice = price * cart.quantity;
                              return (
                                <Container key={index}>
                                  <Row>
                                    <Col
                                      md={3}
                                      className="d-flex flex-column justify-content-center align-items-center"
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        id={`default`}
                                        label={`Renvoyer`}
                                        name={`checked${index}`}
                                        onChange={(e) => {
                                          setFormData(
                                            formData.map((obj, i) => {
                                              if (i === index) {
                                                return {
                                                  ...obj,
                                                  checked: e.target.checked,
                                                };
                                              }
                                              return obj;
                                            })
                                          );
                                        }}
                                      />
                                      {cart.quantity > 1 ? (
                                        <div>
                                          <span className="text-center">
                                            Quantité
                                          </span>
                                          <Form.Range
                                            defaultValue={1}
                                            min={1}
                                            max={cart.quantity}
                                            name={`quantity${index}`}
                                            onChange={(e) => {
                                              setFormData(
                                                formData.map((obj, i) => {
                                                  if (i === index) {
                                                    return {
                                                      ...obj,
                                                      quantity: parseInt(
                                                        e.target.value
                                                      ),
                                                    };
                                                  }
                                                  return obj;
                                                })
                                              );
                                            }}
                                          />
                                          <div className="d-flex justify-content-between">
                                            <span className="font-size-0_7 fontLato color737373">
                                              {1}
                                            </span>
                                            <span className="font-size-1 fontRedHatDisplay color9e9e9e">
                                              {formData[index].quantity}
                                            </span>
                                            <span className="font-size-0_7 fontLato color737373">
                                              {cart.quantity}
                                            </span>
                                          </div>
                                        </div>
                                      ) : null}
                                    </Col>
                                    <Col
                                      sm
                                      className="d-flex flex-column gap-3 p-2"
                                    >
                                      <div>
                                        <span className="fontRedHatDisplay font-size-1_2 color9e9e9e">
                                          {truncateString(item.name)}
                                        </span>
                                      </div>
                                      <div className="font-size-1">
                                        <span className="fontRedHatDisplay color737373">
                                          Prix a l'unité :
                                        </span>
                                        <span>
                                          {" "}
                                          {formatCurrency(item.price)}
                                        </span>
                                      </div>
                                      <div className="font-size-1">
                                        <span className="fontRedHatDisplay color737373">
                                          Quantité achetée :
                                        </span>
                                        <span> {cart.quantity}</span>
                                      </div>
                                      <div className=" font-size-1 ">
                                        <span className="fontRedHatDisplay color737373">
                                          Total avec quantité :
                                        </span>{" "}
                                        <span>
                                          {formatCurrency(itemTotalPrice)}
                                        </span>
                                      </div>
                                      <div>
                                        <Button
                                          onClick={() => goToProduct(item._id)}
                                        >
                                          Afficher l'article
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col
                                      sm={3}
                                      onClick={() => goToProduct(item._id)}
                                      className="imgContainer"
                                    >
                                      <img
                                        src={item.images[0]}
                                        alt=""
                                        className="imgProduct"
                                      />
                                    </Col>
                                  </Row>
                                  <hr />
                                </Container>
                              );
                            })}
                          </div>
                        ) : (
                          <div>
                            <h4 className="text-center p-2">
                              Cette commande vous sera remboursée au moment où
                              nous la recevrons.
                            </h4>
                            {order.metadata.map((cart, index) => {
                              const item = product.find(
                                (i) => i._id === cart.id
                              );
                              if (item == null) return null;
                              const price = formatNumber(item.price);
                              const itemTotalPrice = price * cart.quantity;
                              return (
                                <Container key={index}>
                                  <Row>
                                    <Col
                                      sm
                                      className="d-flex flex-column gap-3 p-2"
                                    >
                                      <div>
                                        <span className="fontRedHatDisplay font-size-1_2 color9e9e9e">
                                          {truncateString(item.name)}
                                        </span>
                                      </div>
                                      <div className="font-size-1">
                                        <span className="fontRedHatDisplay color737373">
                                          Prix a l'unité :
                                        </span>
                                        <span>
                                          {" "}
                                          {formatCurrency(item.price)}
                                        </span>
                                      </div>
                                      <div className="font-size-1">
                                        <span className="fontRedHatDisplay color737373">
                                          Quantité achetée :
                                        </span>
                                        <span> {cart.quantity}</span>
                                      </div>
                                      <div className=" font-size-1 ">
                                        <span className="fontRedHatDisplay color737373">
                                          Total avec quantité :
                                        </span>{" "}
                                        <span>
                                          {formatCurrency(itemTotalPrice)}
                                        </span>
                                      </div>
                                      <div>
                                        <Button
                                          onClick={() => goToProduct(item._id)}
                                        >
                                          Afficher l'article
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col
                                      sm={3}
                                      onClick={() => goToProduct(item._id)}
                                      className="imgContainer"
                                    >
                                      <img
                                        src={item.images[0]}
                                        alt=""
                                        className="imgProduct"
                                      />
                                    </Col>
                                  </Row>
                                  <hr />
                                </Container>
                              );
                            })}
                          </div>
                        )}
                      </Col>
                    ) : (
                      <div className="d-flex justify-content-center vh-25 align-items-center">
                        <ThreeDots color="#00BFFF" height={80} width={80} />
                      </div>
                    )}
                  </Row>

                  <div className="d-flex justify-content-center">
                    <Button
                      disabled={order.return}
                      className="m-5"
                      type="button"
                      onClick={() => {
                        setResData(() =>
                          formData.filter((data) => data.checked === true)
                        );
                        setModalShow(true);
                      }}
                    >
                      Soumettre
                    </Button>
                    <ModalFc
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      onSubmit={onSubmit}
                      resData={resData}
                      msg={msg}
                    />
                  </div>
                </Form>
              </Container>
            </div>
          ) : (
            <div className="d-flex justify-content-center vh-25 align-items-center">
              <ThreeDots color="#00BFFF" height={80} width={80} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Refund;

const ModalFc = ({ show, onHide, onSubmit, resData, msg }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Retour de commandes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {resData && (
          <div>
            {resData.length === 0 ? (
              <Container>
                <span>Aucun produits n'a été sélectionner</span>
              </Container>
            ) : (
              <Container>
                {resData.map((prod, index) => {
                  const price = formatNumber(prod.prix);
                  const itemTotalPrice = price * prod.quantity;
                  return (
                    <Row key={index}>
                      <Col className="d-flex flex-column gap-3">
                        <div>
                          <span className="font-size-1 fontMontserrat color9e9e9e">
                            {truncateName(prod.name)}
                          </span>
                        </div>
                        <div className="fontRedHatDisplay font-size-0_9">
                          <span className=" color737373">Prix a l'unité :</span>
                          <span> {formatCurrency(prod.prix)}</span>
                        </div>
                        <div className="font-size-0_9 fontRedHatDisplay">
                          <span className="color737373 ">Quantité : </span>
                          <span>{prod.quantity}</span>
                        </div>
                        <div className=" font-size-0_9 ">
                          <span className="fontRedHatDisplay color737373">
                            Total avec quentité :
                          </span>{" "}
                          <span>{formatCurrency(itemTotalPrice)}</span>
                        </div>
                      </Col>

                      <Col
                        style={{ cursor: "default" }}
                        sm="5"
                        className="imgContainer"
                      >
                        <img src={prod.img} alt="" className="imgProduct" />
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            )}
          </div>
        )}
        <div className="text-center">
          {!msg ? (
            <Button onClick={onSubmit} type="submit">
              Valider
            </Button>
          ) : (
            <Alert variant="success">
              <span className="font-size-1 ">{msg}</span>
            </Alert>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
