import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useShoppingCart } from "../../services/shoppingCartContext.jsx";
import "../../styles/all.css";
import "../../styles/profil.css";
import { formatCurrency, formatNumber } from "../../services/formatCurrency";
import { truncateString } from "../../services/formatString";

const CartInfoProduct = ({ id, quantity, storeItems }) => {
  const {
    removeFromCart,
    increaseCartQuantity,
    decreaseCartQuantity,
  } = useShoppingCart();
  const item = storeItems.find((i) => i._id === id);
  if (item == null) return null;

  const price = formatNumber(item.price);
  const itemTotalPrice = price * quantity;
  return (
    <Row className="p-lg-5">
      <Col sm className="imgContainer">
        <img src={item.images[0]} alt="" className="imgProduct" />
      </Col>
      <Col sm>
        <Row>
          <span className="fontRedHatDisplay font-size-1_2 color9e9e9e">
            {truncateString(item.name)}
          </span>
        </Row>

        <Row className="font-size-1">
          <Col>
            <span className="fontRedHatDisplay color737373">
              Prix à l'unité :
            </span>
          </Col>
          <Col>
            <span>{formatCurrency(item.price)}</span>
          </Col>
        </Row>
        <Row className="font-size-1">
          <Col>
            <span className="fontRedHatDisplay color737373">quantité :</span>
          </Col>
          <Col>
            <span> {quantity}</span>
          </Col>
        </Row>
        <Row className="font-size-1">
          <Col>
            <span className="fontRedHatDisplay color737373">
              Prix Total avec quentité :
            </span>
          </Col>
          <Col>
            <span>{formatCurrency(itemTotalPrice)}</span>
          </Col>
        </Row>
      </Col>
      <Col lg>
        <div className="d-flex justify-content-between">
          <div className="">
            <Button
              className="button-shop-minus"
              onClick={() => decreaseCartQuantity(item._id)}
            >
              -
            </Button>
            <Button disabled className="button-shopView button-shop">
              {quantity}
            </Button>
            <Button
              className="button-shop-plus"
              onClick={() => increaseCartQuantity(item._id)}
            >
              +
            </Button>
          </div>
          <div>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => removeFromCart(item._id)}
            >
              X
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CartInfoProduct;
