import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { Button } from "react-bootstrap";
import Corgi from "../images/corgi_404.svg";
import "../styles/all.css";

const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(false);
  const param = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const verifyEmailUrl = () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/auth/users/${param.id}/verify/${param.token}`;
        axios.get(url);
        setValidUrl(true);
      } catch (err) {
        setValidUrl(false);
        console.log(err);
      }
    };
    verifyEmailUrl();
  }, [param]);
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      {validUrl ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
          }}
        >
          <div className="container text-center">
            <Alert className="" variant="success">
              Confirmation de votre compte, veuillez ouvrir une session
              maintenant.
            </Alert>
            <Link to="/login">
              <Button>Se connecter</Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-between pb-5">
          <div>
            <h1 className="text-center p-5 m-5 font-size-5 fontMontserrat color1F8ADD">
              ERREUR 404
            </h1>
          </div>
          <div className="w-25">
            <img className="w-100" src={Corgi} alt="" />
          </div>
          <div>
            <h3 className="text-center font-size-3_2 color1F8ADD">
              Cette page n'existe pas
            </h3>
          </div>
          <div>
            <Button onClick={() => navigate("/")}>Retourner à l'accueil</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailVerify;
